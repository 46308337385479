import React, { useState, useEffect } from "react";
import { EditUserProfileToDB } from "../EditToDB/EditUserProfileToDB";
import PopupMessage from "../popupmessage";
import { useUserContext } from "../UserContext";
const EditProfileModal = () => {
  const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? item : "";
  };

  const initialFormData = {
    id: getLocalStorageItem("userid"),
    name: getLocalStorageItem("username"),
    email: getLocalStorageItem("useremail"),
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [passwordError, setPasswordError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");

  const { updateUserName, username } = useUserContext();

  useEffect(() => {
    if (
      !localStorage.getItem("useremail") ||
      !localStorage.getItem("userid") ||
      !localStorage.getItem("username")
    ) {
      window.location.href = "/";
    }
  }, []);

  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    checkforinputs();
    // eslint-disable-next-line
  }, [formData]);

  const checkforinputs = () => {
    const isAnyPasswordNotEmpty =
      formData.currentPassword.trim() !== "" ||
      formData.newPassword.trim() !== "" ||
      formData.repeatPassword.trim() !== "";
    const currentPasswordInputs = document.getElementsByName("currentPassword");
    currentPasswordInputs.forEach((input) => {
      input.required = isAnyPasswordNotEmpty;
    });
    const repeatPasswordInputs = document.getElementsByName("repeatPassword");
    repeatPasswordInputs.forEach((input) => {
      input.required = isAnyPasswordNotEmpty;
    });
    const newPasswordInputs = document.getElementsByName("newPassword");
    newPasswordInputs.forEach((input) => {
      input.required = isAnyPasswordNotEmpty;
    });

    // Check password validity and update password error message
    if (formData.newPassword || formData.repeatPassword) {
      if (
        formData.newPassword.length < 8 &&
        formData.repeatPassword.length > 0
      ) {
        setPasswordError("Password must be at least 8 characters long.");
      } else if (formData.newPassword !== formData.repeatPassword) {
        setPasswordError("New password and repeat password do not match.");
      } else if (
        formData.newPassword === formData.repeatPassword &&
        formData.newPassword === formData.currentPassword &&
        formData.newPassword !== "" &&
        formData.currentPassword !== ""
      ) {
        setPasswordError("New password and old password cannot be the same.");
      } else {
        setPasswordError("");
      }
    } else if (!isAnyPasswordNotEmpty) {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordError !== "") {
      setPasswordError("Please Make sure all fields are validated");
    } else {
      if (
        formData.currentPassword === "" ||
        formData.newPassword === "" ||
        formData.repeatPassword === ""
      ) {
        if (formData.name === username) {
          setPasswordError("New and current Name is Same !");
        } else {
          const newFormData = {
            id: formData.id,
            name: formData.name,
            email: formData.email,
          };

          const st = await EditUserProfileToDB(newFormData);
          if (st === "success") {
            showPopupfunc("success", "Profile Updated");
            updateUserName(newFormData.name);
            localStorage.setItem("username", newFormData.name);
          } else if (st === "failure") {
            showPopupfunc("failure", "Profile Update Error");
          }
        }
      } else {
        const st = await EditUserProfileToDB(formData);
        if (st === "success") {
          showPopupfunc("success", "Profile Updated");
          formData.currentPassword = "";
          formData.newPassword = "";
          formData.repeatPassword = "";
          updateUserName(formData.name);
          localStorage.setItem("username", formData.name);
        } else if (st === "failure") {
          showPopupfunc("failure", "Profile Update Error");
        } else if (st === "wrongpass") {
          showPopupfunc("failure", "Wrong Password");
        }
      }
    }
  };
  return (
    <>
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      <div
        className=""
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className=""
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            padding: "20px",
            width: "92vw",
            height: "70vh",
            marginTop: "150px",
          }}
        >
          <h2 style={{ textAlign: "center", marginBottom: "10px" }} htmlFor="">
            User Details
          </h2>
          {/* <h4 style={{ textAlign: "center", marginBottom: "60px" }} htmlFor="">
            *Any profile update will force a re-login*
          </h4> */}

          <div
            className="city-state-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="city-section"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
                fontWeight: "bold",
              }}
            >
              <div style={{ marginLeft: "30px", width: "50px" }}>Name </div>
              <input
                type="text"
                name="name"
                value={formData.name || ""}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
            <div
              className="state-section"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
                fontWeight: "bold",
              }}
            >
              <div style={{ width: " 50px" }}>Email </div>
              <input
                type="email"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}
                autoComplete="username"
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                readOnly
                required
                disabled
              />
            </div>
          </div>
          <br />
          <div
            className="zip-country-container"
            style={{
              flexGrow: "1",
              display: "flex",
              justifyContent: "space-evenly",
              width: "49%",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              flexFlow: "wrap",
              fontWeight: "bold",
            }}
          >
            Current Password
            <input
              type="password"
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              autoComplete="current-password"
              style={{
                marginLeft: "10px",
                border: "none",
                width: "49%",
                height: "30px",
              }}
            />
          </div>
          <br />
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="zip-section"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
                fontWeight: "bold",
              }}
            >
              New Password
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                autoComplete="new-password"
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
              />
            </div>
            <div
              className="zip-country-container"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
                fontWeight: "bold",
              }}
            >
              Repeat Password
              <input
                type="password"
                name="repeatPassword"
                value={formData.repeatPassword}
                onChange={handleChange}
                autoComplete="new-password"
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
              />
            </div>
          </div>
          <br />
          {passwordError && (
            <p
              style={{
                color: "red",
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "bold",
              }}
            >
              {passwordError}
            </p>
          )}
          <br />
          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              style={{
                cursor: "pointer",
                width: "200px",
                height: "38px",
                background: "#005668",
                borderRadius: "5px",
                color: "white",
                marginTop: "35px",
                fontSize: "x-large",
              }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProfileModal;
