export default function transformData(originalData) {
  const jsonData = [{ name: "locations", children: [] }];

  // Create a map to store unique locations
  const locationMap = new Map();

  originalData.forEach(item => {
    const { location, department, category, name, description } = item;

    if (!locationMap.has(location.store_name)) {
      // Create a new location object
      const newLocation = {
        name: location.store_name,
        children: [],
      };
      locationMap.set(location.store_name, newLocation);
      jsonData[0].children.push(newLocation);
    }

    // Create a new department object
    const newDepartment = {
      name: department.name,
      children: [],
    };

    // Check if the department already exists in the location
    const locationObj = locationMap.get(location.store_name);
    const existingDepartment = locationObj.children.find(dep => (
      dep.name.toLowerCase() === department.name.toLowerCase()
    ));

    if (!existingDepartment) {
      locationObj.children.push(newDepartment);
    }

    // Find the category within the department or create a new one by matching category name
    const targetDepartment = locationObj.children.find(dep => (
      dep.name.toLowerCase() === department.name.toLowerCase()
    ));

    const targetCategory = targetDepartment.children.find(cat => (
      cat.name.toLowerCase() === category.name.toLowerCase()
    ));

    if (targetCategory) {
      // Category exists, push the product data into it
      //console.log(description);
      targetCategory.children.push({ name,description });
    } else {
      //console.log(description);
      // Create a new category object and push the product data into it
      const newCategory = {
        name: category.name,
        children: [{ name,description }],
      };
      targetDepartment.children.push(newCategory);
    }
  });

  return jsonData;
}
