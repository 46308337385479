import api from "../api";

export const AddDocumentsToDB = async (formData) => {
  try {
    if (formData.length === 0) {
      console.error("No files were selected for upload");
      return "failure";
    }

    const myformData = new FormData();
    formData.forEach((file, index) => {
      myformData.append(`files[${index}]`, file);
    });

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const response = await api.post("/api/adddocuments", myformData);

    if (response.status === 201) {
      // Files uploaded successfully
      return "success";
    } else {
      console.error("File upload failed");
      return "failure";
    }
  } catch (error) {
    console.error("An error occurred while adding documents:", error);
    return "failure";
  }
};
