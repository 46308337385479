import React from "react";

const LocationsDropdown = ({
  locations,
  selectLocation,
  setSelectLocation,
}) => {
  return (
    <div className="dropdown-container">
      <label
        style={{ marginLeft: "30px", width: "180px", marginRight: "115px" }}
        htmlFor="locationDropdown"
      >
        Location
      </label>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "250px",
        }}
      >
        <select
          style={{
            backgroundColor: "#005668",
            fontSize: "large",
            color: "rgb(242, 242, 242)",
            height: "50px",
            width: "250px",
            borderRadius: "12px",
            border: "none",
            appearance: "none",
            padding: "0.5rem 1rem",
            cursor: "pointer",
          }}
          id="locationDropdown"
          value={selectLocation}
          onChange={(e) => setSelectLocation(e.target.value)}
          required
        >
          <option value=""></option>

          {locations.map((location) => (
            <option
              style={{ color: "rgb(242, 242, 242)" }}
              key={location.id}
              value={location.id}
            >
              {location.retail_code}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LocationsDropdown;
