import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchLocations } from "../FetchData/FetchLocations";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    width: "60%",
    minHeight: "350px",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 9999,
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

Modal.setAppElement("#root");

const EditDepartmentModal = ({
  isOpen,
  onRequestClose,
  DepartmentData,
  onSave,
}) => {
  const initialFormData = {
    id: "",
    name: "",
    description: "",
    location_id: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (DepartmentData) {
      // console.log(DepartmentData.name);
      setFormData({
        id: DepartmentData.id,
        name: DepartmentData.name || "",
        description: DepartmentData.description || "",
        location_id: DepartmentData.location_id || "",
      });
      fetchAllData();
    }
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        setLocations(locdata);
        // console.log(locations);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [DepartmentData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSave(formData);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Location"
    >
      <h2>Edit Department</h2>
      <>
        <div
          className="add-top-sec"
          style={{
            display: "inline-flex",
            alignItems: "baseline",
            marginTop: "0px",
          }}
        ></div>
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <form
            className="myFormAdd"
            onSubmit={handleSubmit}
            style={{
              fontFamily: "inter",
              backgroundColor: "#f2f2f2",
              PADDING: "20px",
              width: "100%",
            }}
          >
            <h2 htmlFor="">Department Location</h2>

            <LocationsDropdown
              locations={locations}
              selectLocation={formData.location_id}
              setSelectLocation={(value) =>
                setFormData({ ...formData, location_id: value })
              }
            />
            <br></br>

            <h2 htmlFor="">Department Details</h2>

            <div
              className="store-name"
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginLeft: "30px",
                  width: "180px",
                }}
              >
                Department Name{" "}
              </div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "70%",
                  height: "30px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                maxLength={16}
                required
              />
            </div>
            <br></br>
            <div
              className="address"
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "stretch",
              }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Description{" "}
              </div>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "70%",
                  height: "100px",
                  marginBottom: "20px",
                }}
              />
            </div>

            <button
              type="submit"
              style={{
                cursor: "pointer",
                width: "200px",
                marginLeft: "50%",
                height: "38px",
                background: "#005668",
                borderRadius: "5px",
                color: "white",
                display: "flex",
                textalign: "center",
                fontSize: "medium",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Update
            </button>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default EditDepartmentModal;
