import api from "../api";

export const DeleteLocation = async (id) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    await api.delete(`/api/locations/${id}`);
    return "success";
  } catch (error) {
    console.error(`Error deleting location with ID ${id}:`, error);
    return "failure";
  }
};
