import api from "../api";

export const DeleteDepartment = async (id) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.delete(`/api/departments/${id}`);
    return "success";
  } catch (error) {
    console.error("An error occurred while deleting department:", error);
    return "failure";
  }
};
