import api from "../api";

export const fetchColors = async () => {
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    const response = await api.get("/api/getallcolors");
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }

};
