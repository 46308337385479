import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation} from '@fortawesome/free-solid-svg-icons';

const StyledPopupMessage = styled.div`
  position: fixed;
  top: 0;
  left: 40%;
  background-color: #ffffff;
  color: #333333;
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  &.success {
    display: flex;
    color: white;
    background-color: green;
    width: 280px;
    top: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
  
  &.failure {
    display: flex;
    color: white;
    background-color: red;
    width: 280px;
    top: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }

  .message {
    margin-right: 10px;
    font-size: 15px;
  }

  .close-button {
    position: absolute;
    background: none;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-size: 25px;
    /* left: -1px; */
    right: 15px;
  }
`;

const PopupMessage = ({ type, message,setVisible }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    setVisible(false);
  };

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <FontAwesomeIcon style={{height: "20px"}} icon={faCheck} className="icon success" />;
      case 'failure':
        return <FontAwesomeIcon style={{height: "20px"}} icon={faExclamation} className="icon failure" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose(); // Close the popup after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Clear the timer on unmount
        // eslint-disable-next-line
  }, []); // Run only once after the component is mounted

  return (
    <>
      {isVisible && (
        <StyledPopupMessage className={`popup-message ${type}`}>
          {getIcon()}
          <span className="message">{message}</span>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </StyledPopupMessage>
      )}
    </>
  );
};

export default PopupMessage;
