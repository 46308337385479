import React, { useState, useRef, useEffect } from "react";
import Loading from "../Loading";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import "../globalstyle.css";
import { AddDocumentsToDB } from "../AddToDB/AddDocumentsToDB";
import { fetchAllUnSignDocuments } from "../FetchData/FetchAllUnSignDocuments";
import { DeleteFile } from "../DeleteFromDB/DeleteFile";
import PopupMessage from "../popupmessage";
import CustomConfirmationDialog from "../CustomConfirm";

const FolderPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [myloading, setMyLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [unsigndocuments, setUnSignDocuments] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [fileDelId, setFileDelId] = useState(0);

  const locref = useRef(null);
  const depref = useRef(null);

  const [search, setSearch] = React.useState("");
  const [filterType, setFilterType] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uniqueLocations, setuniqueLocations] = useState([]);
  const [uniqueDepartments, setuniqueDepartments] = useState([]);
  const [uniqueCategories, setuniqueCategories] = useState([]);
  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };
  const handleFilterChange = (type, forwhat) => {
    if (forwhat === "unassign") {
      setFilterType(type);
      setSelectedCategory("");
      setSelectedDepartment("");
      setSelectedLocation("");
    }
    if (forwhat === "location") {
      setFilterType(filterType);
    }
  };

  const filteredDocuments = unsigndocuments.filter((item) => {
    if (filterType === "all") {
      // 000 // DONE
      if (locref.current) {
        locref.current.style.display = "block";
      }
      if (depref.current) {
        depref.current.style.display = "block";
      }
      // locref.current.style.display = "block";
      // depref.current.style.display = "block";
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        return true;
      }
      //001 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.category_name === selectedCategory
          );
        }
        return false;
      }
      // 010 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      // 011 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.category_name === selectedCategory
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      //100 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.location_name === selectedLocation
          );
        }
        if (item.documentable_type === "App\\Models\\Location") {
          //  console.log(item.documentable.store_name)
          return item.documentable.store_name === selectedLocation;
        }
        return false;
      }
      //101 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name === selectedLocation
        ) {
          return (
            item.documentable.additional_info.category_name === selectedCategory
          );
        }
        return false;
      }
      //110 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (selectedDepartment || selectedDepartment !== "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name === selectedLocation
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      //111 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name ===
            selectedLocation &&
          item.documentable.additional_info.category_name === selectedCategory
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      return false;
    }
    if (filterType === "assigned") {
      // 000 // DONE
      if (locref.current) {
        locref.current.style.display = "block";
      }
      if (depref.current) {
        depref.current.style.display = "block";
      }
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return item.documentable_type === "App\\Models\\Product";
        }
        if (item.documentable_type === "App\\Models\\Location") {
          return item.documentable_type === "App\\Models\\Location";
        }
      }
      //001 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.category_name === selectedCategory
          );
        }
        return false;
      }
      // 010 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      // 011 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.category_name === selectedCategory
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      //100 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.location_name === selectedLocation
          );
        }
        if (item.documentable_type === "App\\Models\\Location") {
          // console.log(item.documentable.store_name)
          return item.documentable.store_name === selectedLocation;
        }
        return false;
      }
      //101 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name === selectedLocation
        ) {
          return (
            item.documentable.additional_info.category_name === selectedCategory
          );
        }
        return false;
      }
      //110 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (selectedDepartment || selectedDepartment !== "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name === selectedLocation
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      //111 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name ===
            selectedLocation &&
          item.documentable.additional_info.category_name === selectedCategory
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      return false;
    }

    if (filterType === "product") {
      if (locref.current) {
        locref.current.style.display = "block";
      }
      if (depref.current) {
        depref.current.style.display = "block";
      }
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return item.documentable_type === "App\\Models\\Product";
        }
      }
      //001 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.category_name === selectedCategory
          );
        }
        return false;
      }
      // 010 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      // 011 //DONE
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.category_name === selectedCategory
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      //100 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Product") {
          return (
            item.documentable.additional_info.location_name === selectedLocation
          );
        }
        return false;
      }
      //101 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name === selectedLocation
        ) {
          return (
            item.documentable.additional_info.category_name === selectedCategory
          );
        }
        return false;
      }
      //110 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (selectedDepartment || selectedDepartment !== "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name === selectedLocation
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      //111 //DONE
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        selectedDepartment &&
        selectedDepartment !== "All Departments" &&
        selectedCategory &&
        selectedCategory !== "All Categories"
      ) {
        if (
          item.documentable_type === "App\\Models\\Product" &&
          item.documentable.additional_info.location_name ===
            selectedLocation &&
          item.documentable.additional_info.category_name === selectedCategory
        ) {
          return (
            item.documentable.additional_info.department_name ===
            selectedDepartment
          );
        }
        return false;
      }
      return false;
    }
    if (filterType === "location") {
      // 000 // DONE
      if (locref.current) {
        locref.current.style.display = "block";
      }
      if (depref.current) {
        depref.current.style.display = "block";
      }
      if (
        (!selectedLocation || selectedLocation === "All Locations") &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Location") {
          return item.documentable_type === "App\\Models\\Location";
        }
      }
      if (
        selectedLocation &&
        selectedLocation !== "All Locations" &&
        (!selectedDepartment || selectedDepartment === "All Departments") &&
        (!selectedCategory || selectedCategory === "All Categories")
      ) {
        if (item.documentable_type === "App\\Models\\Location") {
          return item.documentable.store_name === selectedLocation;
        }
        return false;
      }

      return false;
    }
    if (filterType === "unassigned") {
      if (locref.current) {
        locref.current.style.display = "block";
      }
      if (depref.current) {
        depref.current.style.display = "block";
      }

      return item.documentable_type === null;
    }
    return true;
  });

  let data = { nodes: filteredDocuments };

  const handleSearchName = (event) => {
    setSearch(event.target.value);
    // setItemsPerPage(unsigndocuments.length);
    // const filteredData = unsigndocuments.filter((item) =>
    //   item.name.toLowerCase().includes(event.target.value.toLowerCase())
    // );
    //console.log(data.nodes);
  };
  data = {
    nodes: data.nodes.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    ),
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const data = await fetchAllUnSignDocuments();
        setUnSignDocuments(data.data);
        // console.log(data.data,"Documents");

        setuniqueLocations(
          Array.from(
            new Set(
              data.data
                .filter(
                  (doc) =>
                    doc.documentable_type === "App\\Models\\Location" ||
                    doc.documentable_type === "App\\Models\\Product"
                )
                .map((doc) => {
                  if (doc.documentable_type === "App\\Models\\Location") {
                    return doc.documentable.store_name;
                  } else if (doc.documentable_type === "App\\Models\\Product") {
                    return doc.documentable.additional_info.location_name;
                  } else {
                    return "";
                  }
                })
                .filter(Boolean) // Filter out null values
            )
          )
        );

        // Extract unique department names
        setuniqueDepartments(
          Array.from(
            new Set(
              data.data
                .filter(
                  (doc) => doc.documentable_type === "App\\Models\\Product"
                )
                .map((doc) => doc.documentable.additional_info.department_name)
            )
          )
        );

        setuniqueCategories(
          Array.from(
            new Set(
              data.data
                .filter(
                  (doc) => doc.documentable_type === "App\\Models\\Product"
                )
                .map((doc) => doc.documentable.additional_info.category_name)
            )
          )
        );

        // console.log(uniqueLocations,"uniqueLocations");
        // console.log(uniqueDepartments,"uniqueDepartments");
        // console.log(uniqueCategories,"uniqueCategories");

        if (isMounted) {
          setUnSignDocuments(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [myloading]);

  const confirmDelete = (id) => {
    setFileDelId(id);
  };
  const handleDelete = async (id) => {
    try {
      await DeleteFile(id).then((st) => {
        if (st && st === "success") {
          setUnSignDocuments(unsigndocuments.filter((file) => file.id !== id));
          showPopupfunc("success", "File Delete Done");
        } else if (st && st === "failure") {
          showPopupfunc("failure", "File Delete Error");
        }
      });
    } catch (error) {
      showPopupfunc("failure", "File Delete Error");
      console.error(`Error deleting location with ID ${id}:`, error);
    }
  };
  const handleNewFolder = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (files) => {
    try {
      setMyLoading(true);
      if (selectedFiles) {
        await AddDocumentsToDB(files).then((st) => {
          if (st && st === "success") {
            showPopupfunc("success", "File Upload Done");
          } else if (st && st === "failure") {
            showPopupfunc("failure", "File Upload Error");
          }
        });
        setMyLoading(false);
      }
    } catch (error) {
      console.error(error);
      showPopupfunc("failure", "File Upload Error");
      setMyLoading(false);
    } finally {
    }
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    //console.log("Selected Files:");
    files.forEach((file) => {
      // console.log(file.name);
    });
    handleFileUpload(files);
  };

  function onSortChange(action, state) {
    // console.log(action, state);
  }
  const theme = useTheme({
    HeaderRow: `
        background-color: #F2F2F2;
      `,
    Row: `
        &:nth-of-type(odd) {
          background-color: #FFFFF;
        }

        &:nth-of-type(even) {
          background-color: #F2F2F2;
        }
      `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
        STORE_ID: (array) => array.sort((a, b) => a.id - b.id),
        DATE_SORT: (array) =>
          array.sort((a, b) => a.created_at.localeCompare(b.created_at)),
      },
    }
  );

  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };

  function convertBytesToKB(bytes) {
    const KB = bytes / 1024;
    return KB.toFixed(2);
  }

  return (
    <div>
      {fileDelId > 0 && (
        <CustomConfirmationDialog
          id={fileDelId}
          actionName={"File"}
          onConfirm={handleDelete}
        ></CustomConfirmationDialog>
      )}
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      {myloading ? (
        <Loading />
      ) : (
        <div>
          <h2 className="welcomeText">Library</h2>
          <button
            type="button"
            className="add-button"
            onClick={handleNewFolder}
          >
            + Add New
          </button>
          <input
            type="file"
            multiple
            accept=".jpg, .jpeg, .png, .gif, .pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <div
            className="allFilters"
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "space-around",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            <label style={{ marginLeft: "5px" }} htmlFor="search">
              Search :
              <input
                style={{ marginLeft: "5px", marginRight: "5px" }}
                id="search"
                type="text"
                onChange={handleSearchName}
              />
            </label>
            Assigned To :
            <select
              style={{ marginLeft: "5px", marginRight: "5px" }}
              value={filterType}
              onChange={(e) => handleFilterChange(e.target.value, "unassign")}
            >
              <option value="all">All</option>
              <option value="assigned">Assigned</option>
              <option value="product">Products</option>
              <option value="location">Locations</option>
              <option value="unassigned">Unassigned</option>
            </select>
            <div ref={locref}>
              Filters :
              <select
                style={{ marginLeft: "5px", marginRight: "5px" }}
                value={selectedLocation || ""}
                onChange={(e) => {
                  setSelectedLocation(e.target.value);
                  handleFilterChange(e.target.value, "location");
                }}
              >
                <option value={null}>All Locations</option>
                {uniqueLocations.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
            <div ref={depref}>
              <select
                style={{ marginLeft: "5px", marginRight: "5px" }}
                value={selectedDepartment || ""}
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);
                  handleFilterChange(e.target.value, "location");
                }}
              >
                <option value={null}>All Departments</option>
                {uniqueDepartments.map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </select>

              <select
                style={{ marginLeft: "5px", marginRight: "5px" }}
                value={selectedCategory || ""}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  handleFilterChange(e.target.value, "location");
                }}
              >
                <option value={null}>All Categories</option>
                {uniqueCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br></br>
          <div className="myTable" style={{ marginTop: "30px" }}>
            <Table data={data} sort={sort} theme={theme}>
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="STORE_ID"
                      >
                        File
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="STORE_NAME"
                      >
                        Added By
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="STORE_NAME"
                      >
                        Assigned To
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="STORE_NAME"
                      >
                        File Format
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="STORE_NAME"
                      >
                        File Size
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DATE_SORT"
                      >
                        Date Added
                      </HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">
                        Delete
                      </HeaderCellSort>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item) => (
                      <Row item={item} key={item.id}>
                        <Cell>
                          <a
                            href={item.file_path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name.split(".").slice(0, -1).join(".")}
                          </a>
                        </Cell>
                        <Cell>{item.added_by_id}</Cell>
                        {item.documentable_type === "App\\Models\\Product" && (
                          <>
                            {
                              //{console.log(item.documentable.additional_info.category_name)}  {console.log(item.documentable.additional_info.location_name)}  {console.log(item.documentable.additional_info.department_name)}  {console.log(item.documentable.name) //Product
                            }
                            {/* <Cell>{"Product: " + item.documentable_id}</Cell> */}
                            <Cell>
                              {item.documentable.additional_info.location_name +
                                " , " +
                                item.documentable.additional_info
                                  .department_name +
                                " , " +
                                item.documentable.additional_info
                                  .category_name +
                                " , " +
                                item.documentable.name}
                            </Cell>
                          </>
                        )}
                        {item.documentable_type === "App\\Models\\Location" && (
                          <Cell>
                            {"Location: " + item.documentable.store_name}
                          </Cell>
                        )}

                        {item.documentable_type === null && (
                          <Cell>{"N/A"}</Cell>
                        )}

                        <Cell>{item.file_format.toUpperCase()}</Cell>
                        <Cell>{convertBytesToKB(item.file_size)} KB</Cell>
                        <Cell>
                          {item.created_at
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </Cell>
                        <Cell>
                          <IconButton
                            color="secondary"
                            aria-label="Delete"
                            onClick={() => confirmDelete(item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FolderPage;
