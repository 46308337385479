import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import "../globalstyle.css";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import DepartmentsDropdown from "../Dropdowns/DepartmentsDropdown";
import { ImportCategoriesToDB } from "../ImportToDB/ImportCategoriesToDB";
import PopupMessage from "../popupmessage";

const ImportCategories = ({ locations, departments }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0); // Add new state for file input key
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");

  useEffect(() => {
    setSelectedFiles(null);
  }, [fileInputKey]); // Reset file input when the key changes

  useEffect(() => {
    setSelectedFiles(null);
  }, []);

  const handleDepartmentChange = (departmentId) => {
    setSelectedDepartment(departmentId);
    setSelectedFiles(null);
    setFileInputKey((prevKey) => prevKey + 1);
  };
  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };
  const handleLocationChange = (locationId) => {
    setSelectedLocation(locationId);
    setSelectedDepartment(null);
    setFileInputKey((prevKey) => prevKey + 1);
    setSelectedFiles(null);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = selectedFiles[0];
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

          const sheetHeaders = [];
          for (let col in firstSheet) {
            if (/^[A-Z]+\d+$/.test(col)) {
              const rowIndex = Number(col.match(/\d+/)[0]);
              if (rowIndex === 1) {
                sheetHeaders.push(firstSheet[col].v);
              }
            }
          }

          //    console.log('Headers in the Excel file:', sheetHeaders);
          const expectedColumns = ["name", "description"];
          const missingColumns = expectedColumns.filter(
            (header) => !sheetHeaders.includes(header)
          );
          if (missingColumns.length === 0) {
            // console.log('Excel file structure is valid');
            try {
              ImportCategoriesToDB(
                selectedFiles[0],
                selectedLocation,
                selectedDepartment
              ).then((st) => {
                //   console.log("OOOO");

                if (st && st === "success") {
                  showPopupfunc("success", "Categories Imported");
                } else if (st && st === "failure") {
                  showPopupfunc(
                    "failure",
                    "Categories Import Error(Duplicates)"
                  );
                }

                setSelectedFiles(null);
                setFileInputKey((prevKey) => prevKey + 1);
              });
            } catch (error) {
              console.log(error, "Error");
              setSelectedFiles(null);
              setFileInputKey((prevKey) => prevKey + 1);
              showPopupfunc("failure", "Categories Import Error(Duplicates)");
            }
          } else {
            //console.error('Missing columns:', missingColumns);
            showPopupfunc("failure", "Excel Missing columns");
          }
        } catch (error) {
          console.error("Error reading Excel file:", error.message);
          showPopupfunc("failure", "Excel Invalid");
        }
      };
    }
    reader.readAsArrayBuffer(file);
    //   console.log("OOO");
    //   console.log(selectedFiles[0]);
  };
  return (
    <>
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      <div
        style={{
          margin: "31px",
          textAlign: "center",
          width: "fit-content",
          background: "#005668",
          fontSize: "xx-large",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <a
          href={"https://aerial.intelly.ca/categories%20-%20Sample.xlsx"}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          Download Sample For Categories Import
        </a>
      </div>
      <br></br>
      <div>
        {locations.length > 0 ? (
          <>
            {departments.length > 0 ? (
              <div>
                <LocationsDropdown
                  locations={locations}
                  selectLocation={selectedLocation}
                  setSelectLocation={handleLocationChange}
                />
                <br></br>

                {selectedLocation && (
                  <DepartmentsDropdown
                    departments={departments}
                    selectDepartment={selectedDepartment}
                    selectedLocation={selectedLocation}
                    setSelectDepartment={handleDepartmentChange}
                  />
                )}
                <br></br>

                {selectedLocation && selectedDepartment && (
                  <>
                    <input
                      key={fileInputKey}
                      type="file"
                      accept=".xlsx"
                      onChange={(e) => {
                        setSelectedFiles(e.target.files);
                      }}
                      style={{ fontSize: "22px", padding: "10px" }}
                    />
                    {selectedFiles ? (
                      <>
                        <button
                          type="button"
                          className="importButton"
                          onClick={handleFileUpload}
                          style={{ fontSize: "18px", padding: "12px" }}
                        >
                          Import
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div>No Departments</div>
            )}
          </>
        ) : (
          <div>No Locations</div>
        )}
      </div>
    </>
  );
};
export default ImportCategories;
