import React, { useState } from "react";
import styled from "styled-components";
import { FogotPasswordReq } from "../ForgotPasswordDB/ForgotPasswordReq";

const ForgotPassword = ({ handleforgotoff }) => {
  const [email, setEmail] = useState("");
  const [msg, setmsg] = useState(null);

  const handleForgot = async (event) => {
    event.preventDefault();
    FogotPasswordReq(email).then((message)=>{setmsg(message);}).catch((e)=>{console.log(e)})
    //success or error
  };

  if (msg === "success") {
    setmsg(
      <div
        style={{
          backgroundColor: "#5CFF1026",
          color: "#52FF00",
          height: "40px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
          flexDirection: "row",
        }}
      >
        {" "}
        Success! You will shortly receive an email{" "}
      </div>
    );
  }

  if (msg === "error") {
    setmsg(
      <div
        style={{
          backgroundColor: "#F1CF8726",
          color: " #FFD98F",
          height: "40px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
          flexDirection: "row",
        }}
      >
        Error! There seems to be an error, No Email Found...
      </div>
    );
  }
  return (
    <div>
      <Container>
        <LeftSide>
          <Logo src="../logo.png" alt="Logo" />
          <Title>AERIAL</Title>
          <Copyright>By Intelly Digital Solutions</Copyright>
          <form onSubmit={handleForgot} style={{ marginTop: "20px" }}>
            <MyLabel>Enter E-Mail</MyLabel>
            <InputField
              type="email"
              placeholder="Email"
              name="email"
              autoComplete="username"
              value={email}
              onChange={(e) => {
                setmsg(null);
                setEmail(e.target.value);
              }}
              required
            />

            <button
              type="submit"
              style={{
                width: "50%",
                height: "25%",
                margin: "0px auto 20px",
                borderRadius: "12px",
                border: "2px solid #fff",
                fontFamily: "sans-serif",
                color: "#fff",
                fontSize: "clamp(10px, 2vw, 36px)",
                fontStyle: "normal",
                fontWeight: "400",
                textAlign: "center",
                lineHeight: "60px",
                cursor: "pointer",
                background: "transparent",
              }}
            >
              Reset Password{" "}
            </button>

            <div
              onClick={handleforgotoff}
              type="button"
              style={{
                width: "25%",
                margin: "0px auto 20px",
                borderRadius: "12px",
                border: "2px solid #fff",
                fontFamily: "sans-serif",
                color: "#fff",
                fontSize: "clamp(10px, 2vw, 36px)",
                fontStyle: "normal",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                lineHeight: "60px",
                cursor: "pointer",
                background: "transparent",
                justifyContent: "space-around",
                alignItems: "stretch",
                alignContent: "space-around",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              Back{" "}
            </div>
          </form>
          <br></br>
          <div> {msg}</div>
        </LeftSide>
        <RightSide>
          <ImageRow>
            <Image src="../asset management icons 3.png" alt="Image 1" />
            <Image src="../asset management icons 2.png" alt="Image 2" />
          </ImageRow>
          <Image src="../asset management icons 1.png" alt="Image 3" />
        </RightSide>
      </Container>
    </div>
  );
};

const MyLabel = styled.div`
  color: white;
  margin-bottom: 20px;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1e1e1e;
  overflow: hidden;
`;

const LeftSide = styled.div`
  flex: 1;
  padding: 3%;
  margin: 7vh 10vh 5vh 5vh;
  height: 60%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 34px;
  border: 1px solid #fff;
`;

const Logo = styled.img`
  max-width: 450px;
  max-height: 125px;
  padding: 5px;
  width: 100%;
  min-height: 20px;
  margin: 0 auto;
`;

const Title = styled.div`
  text-align: center;
  font-size: clamp(10px, 2vw, 36px);
  color: white;
  font-family: "Inter", sans-serif;
`;

const InputField = styled.input`
  width: 65%;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  @media (max-width: 767px) {
    flex: 0;
    display: none;
  }
`;

const ImageRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 65%;
  max-height: 50%;
  object-fit: contain;
`;

const Copyright = styled.div`
  text-align: center;
  font-size: clamp(10px, 1vw, 25px);
  color: #888;
  font-family: "Inter", sans-serif;
`;

export default ForgotPassword;
