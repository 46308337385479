import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";

import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@table-library/react-table-library/theme";
import { fetchUsers } from "../FetchData/FetchUsers";
import { DeleteUser } from "../DeleteFromDB/DeleteUser";
import { EditUserToDB } from "../EditToDB/EditUserToDB";
import AddUsers from "../AddPages/AddUsers";
import EditUserModal from "../EditPages/EditUserModal";
import ViewPermissions from "../ViewPermissions";
import "../globalstyle.css";
import PopupMessage from "../popupmessage";
import CustomConfirmationDialog from "../CustomConfirm";

const Users = () => {
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [users, setUsers] = useState([]);
  const data = { nodes: users };
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [userChangeFlag, setUserChangeFlag] = useState(true);
  const [viewPermissionsData, setViewPermissionsData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [deluserid, setDeluserid] = useState(0);

  const [showViewPermissionsModal, setShowViewPermissionsModal] =
    useState(false);

  const isadmin = localStorage.getItem("is_admin");
  const userid = localStorage.getItem("userid");

  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };
  const toggleAddUserForm = (st) => {
    setShowAddUserForm(!showAddUserForm);
    if (st && st === "success") {
      showPopupfunc("success", "User Added");
    } else if (st && st === "failure") {
      showPopupfunc("failure", "User Add Error");
    }
  };
  function onSortChange(action, state) {
    //console.log(action, state);
  }
  const theme = useTheme({
    HeaderRow: `
          background-color: #F2F2F2;
        `,
    Row: `
          &:nth-of-type(odd) {
            background-color: #FFFFF;
          }
  
          &:nth-of-type(even) {
            background-color: #F2F2F2;
          }
        `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
        DEPT_ID: (array) =>
          array.sort((a, b) => {
            const idA = a.id.toString();
            const idB = b.id.toString();
            return idA.localeCompare(idB);
          }),
        DEPT_NAME: (array) =>
          array.sort((a, b) => a.name.localeCompare(b.name)),
        DEPT_DESC: (array) =>
          array.sort((a, b) => {
            const descriptionA = (a.description || "").toString();
            const descriptionB = (b.description || "").toString();
            return descriptionA.localeCompare(descriptionB);
          }),
        DEPT_DATE: (array) =>
          array.sort((a, b) => a.created_at.localeCompare(b.created_at)),
        DEPT_LOC_NAME: (array) =>
          array.sort((a, b) => a.location_id.localeCompare(b.location_id)),
        DEPT_LOC_ID: (array) =>
          array.sort((a, b) => a.location_id - b.location_id),
      },
    }
  );

  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };

  useEffect(() => {
    async function fetchUsersData() {
      try {
        await fetchUsers().then((users) => {
          setUsers(users);
          // console.log(users);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsersData();
    // eslint-disable-next-line
  }, [userChangeFlag]);

  const confirmDelete = (id) => {
    setDeluserid(id);
  };
  const handleDelete = async (id) => {
    try {
      const result = await DeleteUser(id);
      if (result === "success") {
        setUsers(users.filter((user) => user.id !== id));
        showPopupfunc("success", "User Deleted");
      } else {
        showPopupfunc("failure", "User Delete Error");
      }
    } catch (error) {
      showPopupfunc("failure", "User Delete Error");
      console.error(`Error deleting User with ID ${id}:`, error);
    }
  };
  function handleEdit(userData) {
    setEditUserData(userData);
    setShowEditModal(true);
  }
  const closeEditModal = () => {
    setShowEditModal(false);
    setEditUserData({});
  };
  const handleSaveEdit = async (updatedData) => {
    try {
      if (!updatedData.password) {
        delete updatedData.password;
      }
      const result = await EditUserToDB(updatedData);
      if (result === "success") {
        showPopupfunc("success", "User Edit Done");
      } else {
        showPopupfunc("failure", "User Edit Error");
      }
      setUserChangeFlag(!userChangeFlag);
    } catch (error) {
      console.error(`Error updating User with ID ${updatedData.id}:`, error);
      showPopupfunc("failure", "User Edit Error");
    }
  };

  function handleViewPermissionsClick(item) {
    //console.log(item);
    setViewPermissionsData(item); // Set the user data for the modal
    setShowViewPermissionsModal(true); // Open the modal
  }
  return (
    <div>
      {deluserid > 0 && (
        <CustomConfirmationDialog
          id={deluserid}
          actionName={"user"}
          onConfirm={handleDelete}
        ></CustomConfirmationDialog>
      )}

      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      {showAddUserForm ? (
        <AddUsers
          userChangeFlag={userChangeFlag}
          setUserChangeFlag={setUserChangeFlag}
          toggleAddUserForm={toggleAddUserForm}
        />
      ) : (
        <>
          <div className="text-parent">
            <h2 className="welcomeText">Users</h2>
            <button className="add-button" onClick={toggleAddUserForm}>
              {" "}
              + Add New
            </button>
          </div>
          <div className="myTable" style={{ marginTop: "60px" }}>
            <Table data={data} sort={sort} theme={theme}>
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_LOC_NAME"
                      >
                        ID
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_LOC_ID"
                      >
                        Name
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_DESC"
                      >
                        Email
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_NAME"
                      >
                        Permissions
                      </HeaderCellSort>
                      <HeaderCellSort
                        resize={useResizeColumns}
                        sortKey="DEPT_DATE"
                      >
                        Date Added
                      </HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">Edit</HeaderCellSort>
                      <HeaderCellSort sortKey="STORE_NAME">
                        Delete
                      </HeaderCellSort>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item) => (
                      <Row item={item} key={item.id}>
                        <Cell>{item.id}</Cell>
                        <Cell>{item.name}</Cell>
                        <Cell>{item.email}</Cell>
                        {item.id === 1 || item.is_admin === 1 ? (
                          <Cell>
                            <div>ALL</div>
                          </Cell>
                        ) : (
                          <Cell
                            onClick={() => handleViewPermissionsClick(item)}
                          >
                            <a href="##" role="button">
                              View
                            </a>
                          </Cell>
                        )}
                        <Cell>
                          {item.created_at
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </Cell>
                        <Cell>
                          {item.id === 1 ? (
                            <IconButton
                              color="primary"
                              aria-label="Edit"
                              disabled
                            >
                              <EditIcon />
                            </IconButton>
                          ) : userid !== "1" &&
                            item.is_admin === 1 &&
                            isadmin === "1" ? (
                            <IconButton
                              color="primary"
                              aria-label="Edit"
                              disabled
                            >
                              <EditIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              aria-label="Edit"
                              onClick={() => handleEdit(item)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </Cell>

                        <Cell>
                          {item.id === 1 ? (
                            <IconButton
                              color="secondary"
                              aria-label="Delete"
                              disabled
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : userid !== "1" &&
                            item.is_admin === 1 &&
                            isadmin === "1" ? (
                            <IconButton
                              color="secondary"
                              aria-label="Delete"
                              disabled
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="secondary"
                              aria-label="Delete"
                              onClick={() => confirmDelete(item.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </div>

          <EditUserModal
            isOpen={showEditModal}
            onRequestClose={closeEditModal}
            UserData={editUserData}
            onSave={handleSaveEdit}
          />

          {viewPermissionsData && (
            <ViewPermissions
              isOpen={showViewPermissionsModal} // Control modal visibility
              onRequestClose={() => {
                setShowViewPermissionsModal(false); // Close the modal
                setViewPermissionsData(null); // Clear user data
              }}
              userData={viewPermissionsData} // Pass user data to the modal
            />
          )}
        </>
      )}
    </div>
  );
};

export default Users;
