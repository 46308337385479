import api from "../api";

export const ImportCategoriesToDB = async (formData, locid, depid) => {
  try {
    const myformData = new FormData();
    myformData.append(`files[0]`, formData);
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await api.post("api/importcategories/" + locid + "/" + depid, myformData);
    return "success";
  } catch (error) {
    console.error("An error occurred while importing categories:", error);
    return "failure";
  }
};
