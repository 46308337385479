import api from "../api";

export const AddCategoriesToDB = async (formData) => {
  try {
    const formatData = {
      location_id: formData.location_id,
      department_id: formData.department_id,
      name: formData.cat_name,
      description: formData.cat_desc,
    };
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.post("/api/addcategories", formatData);
    return "success";
  } catch (error) {
    console.error("An error occurred while adding categories:", error);
    return "failure";
  }
};
