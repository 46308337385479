import api from "../api";

export const DeleteFile = async (id) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.delete(`/api/documents/${id}`);
    return "success";
  } catch (error) {
    console.error(
      `An error occurred while deleting file with ID ${id}:`,
      error
    );
    return "failure";
  }
};
