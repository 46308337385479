import React, { useEffect, useState } from "react";
import { useUserContext } from "./UserContext";
import "./globalstyle.css";
import CirclesView from "./CirclesView";
import { fetchColors } from "./FetchData/FetchColors";
import Loading from "./Loading";

const UserDashboard = () => {
  const {
    setShowExtraD,
    userRole,
    logout,
    updateUserRole,
    locationName,
    locationAddress,
  } = useUserContext();
  const [roleValidated, setRoleValidated] = useState(false);
  const [circleColor, setCircleColor] = useState("");
  const [departmentColor, setDepartmentColor] = useState("");
  const [categoryColor, setCategoryColor] = useState("");
  const [productColor, setProductColor] = useState("");
  const colorsAvailable =
    circleColor && departmentColor && categoryColor && productColor;
  // console.log(userRole);
  useEffect(() => {
    setShowExtraD(false);
    if (userRole) {
      fetchColors().then((data) => {
        try {
          setCircleColor(data[0].circle_color);
          setDepartmentColor(data[0].department_color);
          setCategoryColor(data[0].category_color);
          setProductColor(data[0].product_color);
        } catch (error) {
          console.log(error, "error");
        }
      });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (userRole !== "user" || !userRole) {
      const storedUserRole = localStorage.getItem("userRole");
      if (storedUserRole) {
        updateUserRole(storedUserRole);
        //   console.log(storedUserRole,"Stored ROLE");
        setRoleValidated(true);
      }
      if (userRole !== "user") {
        logout();
        window.location.href = "/";
      }
    } else {
      setRoleValidated(true);
    }
    // eslint-disable-next-line
  }, []);
  if (!colorsAvailable && roleValidated) {
    // console.log(circleColor,departmentColor,categoryColor,productColor);
    return <Loading></Loading>;
  } else {
    // console.log("PAssing these to circle VIew",circleColor,departmentColor,categoryColor,productColor);
    return roleValidated ? (
      <div>
        <h2 className="welcomelocText">{locationName}</h2>
        <h2 className="welcomelocText2">{locationAddress}</h2>
        {/* <h2 className="welcomelocTextUSername">{"Hi, "+username.toUpperCase()}</h2> */}

        {/* <h2 className="welcomeText">Admin Dashboard</h2>     */}
        <CirclesView
          circleColor={circleColor}
          departmentColor={departmentColor}
          categoryColor={categoryColor}
          productColor={productColor}
        />
      </div>
    ) : null;
  }
};

export default UserDashboard;
