import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@table-library/react-table-library/theme";
import "../globalstyle.css";
import { fetchLocations } from "../FetchData/FetchLocations";
import AddLocationForm from "../AddPages/AddLocationForm";
import { EditLocationToDB } from "../EditToDB/EditLocationToDB";
import { DeleteLocation } from "../DeleteFromDB/DeleteLocation";
import EditLocation from "../EditPages/EditLocation";
import ViewLocation from "../ViewLocation";
import PopupMessage from "../popupmessage";
import CustomConfirmationDialog from "../CustomConfirm";

const Locations = () => {
  const [showAddLocationForm, setShowAddLocationForm] = useState(false);
  const [showViewLocationForm, setShowViewLocationForm] = useState(false);
  const [sameLocationView, setSameLocationView] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLocationData, setEditLocationData] = useState(null);
  const [locationChangeFlag, setLocationChangeFlag] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [locDelId, setLocDelId] = useState(0);

  //For Pagination And Search
  const [pageRange, setPageRange] = useState({ start: 0, end: 4 });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedData, setpaginatedData] = useState([]);
  let data = { nodes: paginatedData };
  const [search, setSearch] = React.useState("");

  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };

  const handleSearchName = (event) => {
    setSearch(event.target.value);
    setItemsPerPage(locations.length);
    // const filteredData = paginatedData.filter((item) =>
    //   item.store_name.toLowerCase().includes(event.target.value.toLowerCase())
    // );
    // console.log(data.nodes);
  };

  data = {
    nodes: data.nodes.filter((item) =>
      item.store_name.toLowerCase().includes(search.toLowerCase())
    ),
  };
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setpaginatedData(locations.slice(startIndex, endIndex));
    const maxPage = Math.max(1, Math.ceil(locations.length / itemsPerPage));
    setCurrentPage((prevPage) => {
      if (prevPage < 0) return 0;
      if (prevPage >= maxPage) return maxPage - 1;
      return prevPage;
    });
    const maxVisiblePageButtons = 5;
    const newStart = Math.min(
      Math.max(0, currentPage - Math.floor(maxVisiblePageButtons / 2)),
      Math.max(0, maxPage - maxVisiblePageButtons)
    );
    const newEnd = Math.min(newStart + maxVisiblePageButtons, maxPage);
    setPageRange({ start: newStart, end: newEnd - 1 });
  }, [itemsPerPage, currentPage, locations]);

  const handleJumpToPage = (page) => {
    setCurrentPage(page);
  };

  function onSortChange(action, state) {
    //console.log(action, state);
  }
  const theme = useTheme({
    HeaderRow: `
        background-color: #F2F2F2;
      `,
    Row: `
        &:nth-of-type(odd) {
          background-color: #FFFFF;
        }

        &:nth-of-type(even) {
          background-color: #F2F2F2;
        }
      `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
        STORE_NAME: (array) =>
          array.sort((a, b) => a.store_name.localeCompare(b.store_name)),
        STORE_ID: (array) => array.sort((a, b) => a.id - b.id),
      },
    }
  );

  const toggleAddLocationForm = (st) => {
    setShowAddLocationForm(!showAddLocationForm);
    if (st && st === "success") {
      showPopupfunc("success", "Location Added");
    } else if (st && st === "failure") {
      showPopupfunc("failure", "Location Add Error");
    }
  };
  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };

  const toggleViewLocationForm = () => {
    setShowViewLocationForm(!showViewLocationForm);
  };
  const changelocationChangeFlag = () => {
    setLocationChangeFlag(!locationChangeFlag);
  };

  function handleLocationNameClick(item) {
    // console.log("Product Name Clicked:", item);
    setSelectedLocation(item);
    setSameLocationView(true); // Open the view
    if (item === selectedLocation) {
      // console.log("Pr:", sameProductView);
      setSameLocationView(false);
    }
    if (item === selectedLocation && !sameLocationView) {
      // console.log("Pr:", sameProductView);
      setSameLocationView(true);
    }
  }
  useEffect(() => {
    if (selectedLocation) {
      setShowViewLocationForm(true);
      //  console.log(selectedProduct);
    }
    if (selectedLocation && sameLocationView) {
      setShowViewLocationForm(true);
      //  console.log(selectedProduct);
    }
  }, [selectedLocation, sameLocationView]);

  useEffect(() => {
    async function fetchLocationsData() {
      try {
        const data = await fetchLocations();
        setLocations(data);
        // console.log(data[0].retail_code);
      } catch (error) {
        console.error(error);
      }
    }
    fetchLocationsData();
  }, [locationChangeFlag]);

  function handleEdit(locationData) {
    setEditLocationData(locationData);
    setShowEditModal(true);
  }

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditLocationData({});
  };

  const handleSaveEdit = async (updatedData) => {
    // console.log(updatedData);
    try {
      await EditLocationToDB(updatedData).then((st) => {
        setShowEditModal(false);
        setLocationChangeFlag(!locationChangeFlag);

        if (st && st === "success") {
          showPopupfunc("success", "Location Edit Done");
        } else if (st && st === "failure") {
          showPopupfunc("failure", "Location Edit Error");
        }
      });
    } catch (error) {
      console.error(
        `Error updating location with ID ${updatedData.id}:`,
        error
      );
      showPopupfunc("failure", "Location Edit Error");
    }
  };

  const confirmDelete = (id) => {
    setLocDelId(id);
  };

  const handleDelete = async (id) => {
    try {
      await DeleteLocation(id).then((st) => {
        if (st && st === "success") {
          setLocations(locations.filter((location) => location.id !== id));
          showPopupfunc("success", "Location Delete Done");
        } else if (st && st === "failure") {
          showPopupfunc("failure", "Location Delete Error");
        }
      });
    } catch (error) {
      showPopupfunc("failure", "Location Delete Error");
      console.error(`Error deleting location with ID ${id}:`, error);
    }
  };

  return (
    <div>
      {locDelId > 0 && (
        <CustomConfirmationDialog
          id={locDelId}
          actionName={
            "Location, it will delete all departments, categories and products linked with it"
          }
          onConfirm={handleDelete}
        ></CustomConfirmationDialog>
      )}
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      {showAddLocationForm ? (
        <AddLocationForm
          toggleAddLocationForm={toggleAddLocationForm}
          changelocationChangeFlag={changelocationChangeFlag}
        />
      ) : (
        <>
          {showViewLocationForm ? (
            <ViewLocation
              selectedLocation={selectedLocation}
              toggleViewLocationForm={toggleViewLocationForm}
            />
          ) : (
            <>
              {showEditModal ? (
                <>
                  <EditLocation
                    isOpen={showEditModal}
                    onRequestClose={closeEditModal}
                    LocationData={editLocationData}
                    onSave={handleSaveEdit}
                  />
                </>
              ) : (
                <>
                  <div className="text-parent">
                    <h3 className="welcomeText">Locations</h3>
                    <div
                      className="Locadd-button"
                      onClick={toggleAddLocationForm}
                      title="New Location"
                    >
                      + Add New
                    </div>
                  </div>
                  <br></br>
                  {/* //For Pagination And Search */}
                  <div className="mySearch" style={{ display: "flex" }}>
                    <label htmlFor="search">
                      Search :
                      <input
                        id="search"
                        type="text"
                        onChange={handleSearchName}
                      />
                    </label>
                  </div>
                  {/* //For Pagination And Search */}
                  <div className="myLocTable" style={{ marginTop: "30px" }}>
                    <Table data={data} sort={sort} theme={theme}>
                      {(tableList) => (
                        <>
                          <Header>
                            <HeaderRow>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="STORE_ID"
                              >
                                ID
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="STORE_NAME"
                              >
                                Store Name
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="STORE_NAME"
                              >
                                Retail Code{" "}
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="STORE_NAME"
                              >
                                Address
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="STORE_NAME"
                              >
                                Zip Code
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="STORE_NAME"
                              >
                                Date Added
                              </HeaderCellSort>
                              <HeaderCellSort sortKey="STORE_NAME">
                                Edit
                              </HeaderCellSort>
                              <HeaderCellSort sortKey="STORE_NAME">
                                Delete
                              </HeaderCellSort>
                            </HeaderRow>
                          </Header>

                          <Body>
                            {tableList.map((item) => (
                              <Row item={item} key={item.id}>
                                <Cell>{item.id}</Cell>
                                {/* <Cell>{item.store_name}</Cell> */}

                                <Cell
                                  onClick={() => handleLocationNameClick(item)}
                                >
                                  <a href="##" role="button">
                                    {item.store_name}
                                  </a>
                                </Cell>

                                <Cell>{item.retail_code}</Cell>
                                <Cell>{`${item.address}, ${item.city}, ${item.state}, ${item.country}`}</Cell>
                                <Cell>{item.zip_code}</Cell>
                                <Cell>
                                  {item.created_at
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </Cell>

                                <Cell>
                                  <IconButton
                                    color="primary"
                                    aria-label="Edit"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Cell>
                                <Cell>
                                  <IconButton
                                    color="secondary"
                                    aria-label="Delete"
                                    onClick={() => confirmDelete(item.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Cell>
                              </Row>
                            ))}
                          </Body>
                        </>
                      )}
                    </Table>
                  </div>
                  {/* //For Pagination And Search */}
                  <div className="myPagination" style={{ display: "flex" }}>
                    <div
                      className="pagination-controls"
                      style={{ marginRight: "10px" }}
                    >
                      <button
                        onClick={() =>
                          setCurrentPage(Math.max(currentPage - 1, 0))
                        }
                        disabled={currentPage === 0}
                      >
                        Previous
                      </button>
                      <button
                        onClick={() =>
                          setCurrentPage(
                            Math.min(
                              currentPage + 1,
                              Math.ceil(locations.length / itemsPerPage) - 1
                            )
                          )
                        }
                        disabled={
                          currentPage ===
                          Math.ceil(locations.length / itemsPerPage) - 1
                        }
                      >
                        Next
                      </button>
                    </div>

                    {/* Page navigation */}
                    {Math.ceil(locations.length / itemsPerPage) > 1 && (
                      <div>
                        {Array.from({
                          length: pageRange.end - pageRange.start + 1,
                        }).map((_, index) => {
                          const page = pageRange.start + index;
                          return (
                            <button
                              key={page}
                              onClick={() => handleJumpToPage(page)}
                              className={currentPage === page ? "active" : ""}
                            >
                              {page + 1}
                            </button>
                          );
                        })}
                      </div>
                    )}

                    <select
                      style={{ marginLeft: "5px" }}
                      value={itemsPerPage}
                      onChange={(e) =>
                        handleItemsPerPageChange(parseInt(e.target.value))
                      }
                    >
                      <option value={1}>1 per page</option>
                      <option value={10}>10 per page</option>
                      <option value={20}>20 per page</option>
                      <option value={50}>50 per page</option>
                      <option value={locations.length}>All</option>
                    </select>

                    <div style={{ marginLeft: "5px" }}>
                      Total Records: {locations.length}
                    </div>
                  </div>
                  {/* //For Pagination And Search */}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Locations;
