import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";

import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@table-library/react-table-library/theme";

import { fetchProducts } from "../FetchData/fetchProducts";
import AddProducts from "../AddPages/AddProducts";
import "../globalstyle.css";
import { DeleteProduct } from "../DeleteFromDB/DeleteProduct";
import { EditProductToDB } from "../EditToDB/EditProductToDB";
import ViewProduct from "../ViewProduct";
import EditProduct from "../EditPages/EditProduct";
import PopupMessage from "../popupmessage";
import CustomConfirmationDialog from "../CustomConfirm";

const TablePage = () => {
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [showViewProductForm, setShowViewProductForm] = useState(false);
  const [sameProductView, setSameProductView] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  // const data = { nodes: products };
  const [showEditModal, setShowEditModal] = useState(false);
  const [editProductData, setEditProductData] = useState(null);
  const [proDelId, setProDelId] = useState(0);

  const [productChangeFlag, setProductChangeFlag] = useState(true);

  //For Pagination and Search

  const [locations, setLocations] = useState([]); // Maintain state for locations
  const [departments, setDepartments] = useState([]); // Maintain state for departments
  const [categories, setCategories] = useState([]); // Maintain state for departments
  const [selectedLocation, setSelectedLocation] = useState("All"); // Selected location filter
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [pageRange, setPageRange] = useState({ start: 0, end: 4 });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedData, setpaginatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");

  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };

  useEffect(() => {
    const uniqueLocations = Array.from(
      new Set(products.map((item) => item.location.store_name))
    );
    const uniqueDepartments = Array.from(
      new Set(products.map((item) => item.department.name))
    );
    const uniqueCategories = Array.from(
      new Set(products.map((item) => item.category.name))
    );

    setLocations(["All", ...uniqueLocations]);
    setDepartments(["All", ...uniqueDepartments]);
    setCategories(["All", ...uniqueCategories]);
  }, [products]);

  const handleLocationFilterChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  // Handle department filter change
  const handleDepartmentFilterChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleCategoryFilterChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  let data = { nodes: paginatedData };
  const [search, setSearch] = React.useState("");
  const handleSearchName = (event) => {
    setSearch(event.target.value);
    setItemsPerPage(products.length);
    // const filteredData = paginatedData.filter((item) =>
    //   item.name.toLowerCase().includes(event.target.value.toLowerCase())
    // );
    //console.log(data.nodes);
  };
  data = {
    nodes: data.nodes.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    ),
  };
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(0);
  };
  useEffect(() => {
    let filteredData = products;
    if (selectedLocation !== "All") {
      filteredData = filteredData.filter(
        (item) => item.location.store_name === selectedLocation
      );
    }
    if (selectedDepartment !== "All") {
      filteredData = filteredData.filter(
        (item) => item.department.name === selectedDepartment
      );
    }
    if (selectedCategory !== "All") {
      filteredData = filteredData.filter(
        (item) => item.category.name === selectedCategory
      );
    }
    setFilteredData(filteredData);
    //console.log(filteredData);
  }, [products, selectedLocation, selectedDepartment, selectedCategory]);

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, endIndex);
    setpaginatedData(paginatedData);
    const maxPage = Math.max(1, Math.ceil(filteredData.length / itemsPerPage));
    setCurrentPage((prevPage) => {
      if (prevPage < 0) return 0;
      if (prevPage >= maxPage) return maxPage - 1;
      return prevPage;
    });
    const maxVisiblePageButtons = 5;
    const newStart = Math.min(
      Math.max(0, currentPage - Math.floor(maxVisiblePageButtons / 2)),
      Math.max(0, maxPage - maxVisiblePageButtons)
    );
    const newEnd = Math.min(newStart + maxVisiblePageButtons, maxPage);
    setPageRange({ start: newStart, end: newEnd - 1 });
  }, [itemsPerPage, currentPage, filteredData]);

  const handleJumpToPage = (page) => {
    setCurrentPage(page);
  };

  const toggleAddProductForm = (st) => {
    setShowAddProductForm(!showAddProductForm);
    if (st && st === "success") {
      showPopupfunc("success", "Poduct Added");
    } else if (st && st === "failure") {
      showPopupfunc("failure", "Product Add Error");
    }
  };

  const toggleViewProductForm = () => {
    setShowViewProductForm(!showViewProductForm);
  };
  function onSortChange(action, state) {
    // console.log(action, state);
  }
  const theme = useTheme({
    HeaderRow: `
          background-color: #F2F2F2;
        `,
    Row: `
          &:nth-of-type(odd) {
            background-color: #FFFFF;
          }
  
          &:nth-of-type(even) {
            background-color: #F2F2F2;
          }
        `,
  });
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },

    {
      sortIcon: {
        iconDefault: null,
        iconUp: null,
        iconDown: null,
      },
      sortFns: {
        DEPT_ID: (array) =>
          array.sort((a, b) => {
            const idA = a.id.toString();
            const idB = b.id.toString();
            return idA.localeCompare(idB);
          }),
        DEPT_NAME: (array) =>
          array.sort((a, b) => a.name.localeCompare(b.name)),
        DEPT_DESC: (array) =>
          array.sort((a, b) => {
            const descriptionA = (a.description || "").toString();
            const descriptionB = (b.description || "").toString();
            return descriptionA.localeCompare(descriptionB);
          }),
        DEPT_DATE: (array) =>
          array.sort((a, b) => a.created_at.localeCompare(b.created_at)),
        DEPT_LOC_NAME: (array) =>
          array.sort((a, b) => a.location_id.localeCompare(b.location_id)),
        DEPT_LOC_ID: (array) =>
          array.sort((a, b) => a.location_id - b.location_id),
      },
    }
  );

  const useResizeColumns = { resizerHighlight: "#dde2eb", resizerWidth: 25 };
  useEffect(() => {
    async function fetchProductsData() {
      try {
        const data = await fetchProducts();
        setProducts(data);
        //  console.log(products);
      } catch (error) {
        console.error(error);
      }
    }
    fetchProductsData();
    // eslint-disable-next-line
  }, [productChangeFlag]);

  const confirmDelete = (id) => {
    setProDelId(id);
  };
  const handleDelete = async (id) => {
    try {
      await DeleteProduct(id).then((st) => {
        if (st && st === "success") {
          setProducts(products.filter((product) => product.id !== id));
          showPopupfunc("success", "Product Deleted");
        } else if (st && st === "failure") {
          showPopupfunc("failure", "Product Delete Error");
        }
      });
    } catch (error) {
      showPopupfunc("failure", "Product Delete Error");
      console.error(`Error deleting Product with ID ${id}:`, error);
    }
  };
  function handleEdit(productData) {
    // console.log(productData);
    setEditProductData(productData);
    setShowEditModal(true);
  }
  function handleProductNameClick(item) {
    // console.log("Product Name Clicked:", item);
    setSelectedProduct(item);
    setSameProductView(true); // Open the view
    if (item === selectedProduct) {
      // console.log("Pr:", sameProductView);
      setSameProductView(false);
    }
    if (item === selectedProduct && !sameProductView) {
      // console.log("Pr:", sameProductView);
      setSameProductView(true);
    }
  }

  useEffect(() => {
    if (selectedProduct) {
      setShowViewProductForm(true);
      //  console.log(selectedProduct);
    }
    if (selectedProduct && sameProductView) {
      setShowViewProductForm(true);
      //  console.log(selectedProduct);
    }
  }, [selectedProduct, sameProductView]);

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditProductData({});
  };
  const handleSaveEdit = async (updatedData) => {
    try {
      const st = await EditProductToDB(updatedData);
      setShowEditModal(false);
      if (st && st === "success") {
        setProductChangeFlag(!productChangeFlag);
        showPopupfunc("success", "Product Edit Done");
      } else if (st && st === "failure") {
        showPopupfunc("failure", "Product Edit Error");
      }
    } catch (error) {
      console.error(`Error updating Product with ID ${updatedData.id}:`, error);
      showPopupfunc("failure", "Product Edit Error");
    }
  };

  return (
    <div>
      {proDelId > 0 && (
        <CustomConfirmationDialog
          id={proDelId}
          actionName={"Product"}
          onConfirm={handleDelete}
        ></CustomConfirmationDialog>
      )}
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      {showAddProductForm ? (
        <AddProducts
          productChangeFlag={productChangeFlag}
          setProductChangeFlag={setProductChangeFlag}
          toggleAddProductForm={toggleAddProductForm}
        />
      ) : (
        <>
          {showViewProductForm ? (
            <ViewProduct
              selectedProduct={selectedProduct}
              toggleViewProductForm={toggleViewProductForm}
            />
          ) : (
            <>
              {showEditModal ? (
                <>
                  <EditProduct
                    isOpen={showEditModal}
                    onRequestClose={closeEditModal}
                    ProductData={editProductData}
                    onSave={handleSaveEdit}
                  />
                </>
              ) : (
                <>
                  <div className="text-parent">
                    <h2 className="welcomeText">Products</h2>
                    <button
                      className="add-button"
                      onClick={toggleAddProductForm}
                    >
                      {" "}
                      + Add New
                    </button>
                  </div>
                  <div
                    className="myPSearch"
                    style={{
                      display: "flex",
                      marginTop: "70px",
                      flexWrap: "wrap",
                    }}
                  >
                    <label htmlFor="search">
                      Search :
                      <input
                        id="search"
                        type="text"
                        onChange={handleSearchName}
                      />
                    </label>
                    <div
                      className="Catlocation-filter"
                      style={{
                        marginLeft: "5px",
                        minWidth: "300px",
                      }}
                    >
                      <label htmlFor="location-filter">
                        Filter by Location:{" "}
                      </label>
                      <select
                        id="location-filter"
                        value={selectedLocation}
                        onChange={handleLocationFilterChange}
                      >
                        {locations.map((location) => (
                          <option key={location} value={location}>
                            {location}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="Catdepartment-filter"
                      style={{
                        marginLeft: "5px",
                        minWidth: "300px",
                      }}
                    >
                      <label htmlFor="department-filter">
                        Filter by Department:{" "}
                      </label>
                      <select
                        id="department-filter"
                        value={selectedDepartment}
                        onChange={handleDepartmentFilterChange}
                      >
                        {departments.map((department) => (
                          <option key={department} value={department}>
                            {department}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="Catdepartment-filter"
                      style={{
                        marginLeft: "5px",
                        minWidth: "300px",
                      }}
                    >
                      <label htmlFor="department-filter">
                        Filter by Category:{" "}
                      </label>
                      <select
                        id="department-filter"
                        value={selectedCategory}
                        onChange={handleCategoryFilterChange}
                      >
                        {categories.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="myTable" style={{ marginTop: "60px" }}>
                    {
                      //  console.log(products)
                    }
                    <Table data={data} sort={sort} theme={theme}>
                      {(tableList) => (
                        <>
                          <Header>
                            <HeaderRow>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="DEPT_LOC_NAME"
                              >
                                Location
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="DEPT_LOC_ID"
                              >
                                Department
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="DEPT_DESC"
                              >
                                Category
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="DEPT_NAME"
                              >
                                Name
                              </HeaderCellSort>
                              <HeaderCellSort
                                resize={useResizeColumns}
                                sortKey="DEPT_DATE"
                              >
                                Date Added
                              </HeaderCellSort>
                              <HeaderCellSort sortKey="STORE_NAME">
                                Edit
                              </HeaderCellSort>
                              <HeaderCellSort sortKey="STORE_NAME">
                                Delete
                              </HeaderCellSort>
                            </HeaderRow>
                          </Header>

                          <Body>
                            {tableList.map((item) => (
                              <Row item={item} key={item.id}>
                                <Cell>{item.location.store_name}</Cell>
                                <Cell>{item.department.name}</Cell>
                                <Cell>{item.category.name}</Cell>

                                {/* <Cell>{item.name}</Cell> */}
                                <Cell
                                  onClick={() => handleProductNameClick(item)}
                                >
                                  <a href="##" role="button">
                                    {item.name}
                                  </a>
                                </Cell>

                                <Cell>
                                  {item.created_at
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </Cell>
                                <Cell>
                                  <IconButton
                                    color="primary"
                                    aria-label="Edit"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Cell>
                                <Cell>
                                  <IconButton
                                    color="secondary"
                                    aria-label="Delete"
                                    onClick={() => confirmDelete(item.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Cell>
                              </Row>
                            ))}
                          </Body>
                        </>
                      )}
                    </Table>
                  </div>

                  {/* //For Pagination And Search */}
                  <div className="myPagination" style={{ display: "flex" }}>
                    <div
                      className="pagination-controls"
                      style={{ marginRight: "10px" }}
                    >
                      <button
                        onClick={() =>
                          setCurrentPage(Math.max(currentPage - 1, 0))
                        }
                        disabled={currentPage === 0}
                      >
                        Previous
                      </button>
                      <button
                        onClick={() =>
                          setCurrentPage(
                            Math.min(
                              currentPage + 1,
                              Math.ceil(products.length / itemsPerPage) - 1
                            )
                          )
                        }
                        disabled={
                          currentPage ===
                          Math.ceil(products.length / itemsPerPage) - 1
                        }
                      >
                        Next
                      </button>
                    </div>

                    {/* Page navigation */}
                    {Math.ceil(products.length / itemsPerPage) > 1 && (
                      <div>
                        {Array.from({
                          length: pageRange.end - pageRange.start + 1,
                        }).map((_, index) => {
                          const page = pageRange.start + index;
                          return (
                            <button
                              key={page}
                              onClick={() => handleJumpToPage(page)}
                              className={currentPage === page ? "active" : ""}
                            >
                              {page + 1}
                            </button>
                          );
                        })}
                      </div>
                    )}

                    <select
                      style={{ marginLeft: "5px" }}
                      value={itemsPerPage}
                      onChange={(e) =>
                        handleItemsPerPageChange(parseInt(e.target.value))
                      }
                    >
                      <option value={1}>1 per page</option>
                      <option value={10}>10 per page</option>
                      <option value={20}>20 per page</option>
                      <option value={50}>50 per page</option>
                      <option value={products.length}>All</option>
                    </select>

                    <div style={{ marginLeft: "5px" }}>
                      Total Records: {products.length}
                    </div>
                  </div>
                  <br></br>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TablePage;
