import api from "../api";

export const EditUserProfileToDB = async (updatedData) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.post(`/api/updateUserProfile`, updatedData);
    return "success";
  } catch (error) {
    if (error.response.data.message === "Current password is incorrect") {
      return "wrongpass";
    }
    console.error("Error editing user:", error);
    return "failure";
  }
};
