import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledDialog = styled.div`
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 15px;
  }

  button {
    margin-right: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #f0f0f0;
  }
`;

const CustomConfirmationDialog = ({ id, actionName, onConfirm }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
}, [id]);

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm(id);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <StyledDialog>
          <div className="custom-dialog">
            <p>Are you sure you want to delete this {actionName}?</p>
            <button onClick={handleConfirm}>Yes</button>
            <button onClick={handleCancel}>No</button>
          </div>
        </StyledDialog>
      )}
    </>
  );
};

export default CustomConfirmationDialog;
