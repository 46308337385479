import api from "../api";

export const EditColors = async (updatedData) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.put(`/api/updatecolor`, updatedData);
    return "success";
  } catch (error) {
    console.error("An error occurred while editing colors:", error);
    return "failure";
  }
};
