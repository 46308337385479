import api from "../api";

export const EditLocationToDB = async (updatedData) => {
  try {
    const jsonData = JSON.stringify(updatedData);

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "application/json";

    const response = await api.put(
      `/api/locations/${updatedData.id}`,
      jsonData
    );

    if (response.status === 200) {
      return "success";
    } else {
      return "failure";
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return "failure";
  }
};
