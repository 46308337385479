import api from "../api";

export const AddDepartmentsToDB = async (formData) => {
  try {
    const formatData = {
      location_id: formData.location_id,
      name: formData.dept_name,
      description: formData.dept_desc,
    };
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.post("/api/adddepartment", formatData);
    return "success";
  } catch (error) {
    console.error("An error occurred while adding department:", error);
    return "failure";
  }
};
