import React, { useState, useEffect } from "react";
import "../globalstyle.css";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";
import DepartmentsDropdown from "../Dropdowns/DepartmentsDropdown";
import CategoriesDropdown from "../Dropdowns/CategoriesDropdown";
import { fetchCategories } from "../FetchData/FetchCategories";
import { AddProductsToDB } from "../AddToDB/AddProductsToDB";
import { FetchUnAssignedDocs } from "../FetchData/FetchUnAssignedDocs";

const AddProducts = (props) => {
  const { toggleAddProductForm, productChangeFlag, setProductChangeFlag } =
    props;
  const [formData, setFormData] = useState({
    location_id: undefined,
    department_id: undefined,
    cat_id: undefined,
    product_name: "",
    assignedto: "",
    vendorname: "",
    date_bought: "",
    vendorphone: "",
    tags: "",
    product_desc: "",
    product_image: undefined,
    productDocuments: [],
  });
  const [unAssignedDocuments, setUnAssignedDocuments] = useState(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        const deptdata = await fetchDepartments();
        const catdata = await fetchCategories();
        const data = await FetchUnAssignedDocs();
        setUnAssignedDocuments(data.data);
        setCategories(catdata);
        setLocations(locdata);
        setDepartments(deptdata);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllData();
  }, []);
  useEffect(() => {
    // Use the useEffect hook to observe changes in selectedImage
    if (selectedImage) {
      setFormData({ ...formData, product_image: selectedImage[0] });
      //console.log(selectedImage[0],"in UseEffect"); // Log the selectedImage here
    }
    if (!selectedImage) {
      setFormData({ ...formData, image: undefined });
      // console.log(selectedImage[0],"in UseEffect");
    }
    // eslint-disable-next-line
  }, [selectedImage]);

  useEffect(() => {
    if (selectedDocuments) {
      setFormData({ ...formData, productDocuments: selectedDocuments });
    }
    // eslint-disable-next-line
  }, [selectedDocuments]);

  const handleFileChange = (e) => {
    const name = e.target.name;
    if (name === "image") {
      const file = e.target.files;
      setSelectedImage(file);
    } else if (name === "selectedDocument") {
      const selectedDocumentId = parseInt(e.target.value, 10);
      if (
        isNaN(selectedDocumentId) &&
        selectedDocuments !== null &&
        selectedDocuments.length > 0
      ) {
        const lastSelectedDoc = selectedDocuments[selectedDocuments.length - 1];
        setSelectedDocuments((previousState) =>
          previousState.filter((document) => document.id !== lastSelectedDoc.id)
        );

        const myObj = {
          id: lastSelectedDoc.id,
          name: lastSelectedDoc.name,
        };
        setUnAssignedDocuments((prevSelected) => [...prevSelected, myObj]);
      } else if (
        selectedDocuments.some((doc) => doc.id === selectedDocumentId)
      ) {
        const selectedDoc = selectedDocuments.find(
          (doc) => doc.id === selectedDocumentId
        );
        if (selectedDoc) {
          setSelectedDocuments((previousState) =>
            previousState.filter(
              (document) => document.id !== selectedDocumentId
            )
          );

          const myObj = {
            id: selectedDoc.id,
            name: selectedDoc.name,
          };

          setUnAssignedDocuments((prevSelected) => [...prevSelected, myObj]);
        }
      } else if (
        unAssignedDocuments.some((doc) => doc.id === selectedDocumentId)
      ) {
        // If the document is in unAssignedDocuments, move it to selectedDocuments
        const selectedDoc = unAssignedDocuments.find(
          (doc) => doc.id === selectedDocumentId
        );

        if (selectedDoc) {
          setUnAssignedDocuments((previousState) =>
            previousState.filter(
              (document) => document.id !== selectedDocumentId
            )
          );

          const myObj = {
            id: Number(selectedDoc.id),
            name: selectedDoc.name,
          };

          setSelectedDocuments((prevSelected) => [...prevSelected, myObj]);
        }
      } else {
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithFiles = new FormData();
      formDataWithFiles.append("location_id", formData.location_id);
      formDataWithFiles.append("department_id", formData.department_id);
      formDataWithFiles.append("category_id", formData.cat_id);
      formDataWithFiles.append("name", formData.product_name);
      formDataWithFiles.append("assignedto", formData.assignedto);
      formDataWithFiles.append("vendorname", formData.vendorname);
      formDataWithFiles.append("date_bought", formData.date_bought);
      formDataWithFiles.append("vendorphone", formData.vendorphone);
      formDataWithFiles.append("tags", formData.tags);
      formDataWithFiles.append("description", formData.product_desc);
      if (formData.product_image) {
        formDataWithFiles.append("image", formData.product_image);
      }
      if (
        Array.isArray(formData.productDocuments) &&
        formData.productDocuments != null
      ) {
        const documentIds = formData.productDocuments.map((doc) => doc.id);
        formDataWithFiles.append("productDocuments", documentIds);
      }

      //console.log(formDataWithFiles);
      AddProductsToDB(formDataWithFiles).then((st) => {
        setProductChangeFlag(!productChangeFlag);
        toggleAddProductForm(st);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const clearInputField = () => {
    const input = document.querySelector('input[name="image"]');
    if (input) {
      input.value = ""; // Reset the input field
    }
  };

  return (
    <>
      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={toggleAddProductForm}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">Add New Product</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            PADDING: "20px",
            width: "100%",
          }}
        >
          <h2 style={{ marginLeft: "30px" }} htmlFor="">
            Product Location
          </h2>
          <div style={{ display: "flex" }}>
            <LocationsDropdown
              locations={locations}
              selectLocation={formData.location_id}
              setSelectLocation={(value) =>
                setFormData({ ...formData, location_id: value })
              }
            />
            <br></br>
            {formData.location_id && (
              <DepartmentsDropdown
                departments={departments}
                selectDepartment={formData.department_id}
                selectedLocation={formData.location_id}
                setSelectDepartment={(value) =>
                  setFormData({ ...formData, department_id: value })
                }
              />
            )}

            {formData.location_id && formData.department_id && (
              <CategoriesDropdown
                selectedLocation={formData.location_id}
                selectedDepartment={formData.department_id}
                categories={categories}
                selectCategory={formData.cat_id}
                setSelectCategory={(value) =>
                  setFormData({ ...formData, cat_id: value })
                }
              />
            )}

            <br></br>
          </div>
          <h2 style={{ marginLeft: "30px" }} htmlFor="">
            Product Details
          </h2>

          <div
            className="city-state-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="city-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Product Name{" "}
              </div>
              <input
                type="text"
                name="product_name"
                value={formData.product_name}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                maxLength={17}
                required
              />
            </div>
            <div
              className="state-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: " 170px" }}>Assigned To </div>
              <input
                type="text"
                name="assignedto"
                value={formData.assignedto}
                onChange={handleChange}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
              />
            </div>
          </div>
          <br></br>
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="zip-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Vendor Name{" "}
              </div>
              <input
                type="text"
                name="vendorname"
                value={formData.vendorname}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
              />
            </div>
            <div
              className="country-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: "170px" }}>Date Bought </div>
              <input
                type="text"
                name="date_bought"
                value={formData.date_bought}
                onChange={handleChange}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
              />
            </div>
          </div>
          <br></br>
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="zip-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Vendor Phone{" "}
              </div>
              <input
                type="text"
                name="vendorphone"
                value={formData.vendorphone}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
              />
            </div>
            <div
              className="country-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: "170px" }}>Tags # </div>
              <input
                type="text"
                name="tags"
                value={formData.tags}
                onChange={handleChange}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
              />
            </div>
          </div>
          <br></br>
          <div
            className="address"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "stretch",
            }}
          >
            <h2 style={{ marginLeft: "30px", width: "350px" }}>
              Additional Comments{" "}
            </h2>
          </div>

          <br></br>
          <div>
            <input
              type="text"
              name="product_desc"
              value={formData.product_desc}
              onChange={handleChange}
              style={{
                marginLeft: "30px",
                border: "none",
                width: "70%",
                height: "100px",
                marginBottom: "20px",
              }}
            />
          </div>

          <div className="product-docs" style={{ marginLeft: "30px" }}>
            <h2>Documents Assign</h2>
            <p>Hold CTRL + Click to deselect documents</p>

            <select
              name="selectedDocument"
              value={selectedDocuments.map((doc) => doc.id)}
              onChange={handleFileChange}
              multiple
              onClick={() => {
                if (selectedDocuments.length === 1) {
                  const lastSelectedDoc =
                    selectedDocuments[selectedDocuments.length - 1];
                  setSelectedDocuments((previousState) =>
                    previousState.filter(
                      (document) => document.id !== lastSelectedDoc.id
                    )
                  );
                  const myObj = {
                    id: lastSelectedDoc.id,
                    name: lastSelectedDoc.name,
                  };
                  setUnAssignedDocuments((prevSelected) => [
                    ...prevSelected,
                    myObj,
                  ]);
                }
              }}
            >
              {/* {unAssignedDocuments && selectedDocuments ?( <>
    {unAssignedDocuments.length === 0 && selectedDocuments.length === 0 ?(<>
  <option value="no-documents">No available documents</option>
    </>):( 
      <>
      {selectedDocuments &&
  selectedDocuments.map((doc) => (
    <option key={"SelectedDoc" + doc.id} value={doc.id}>
      {doc.name}
    </option>
  ))}
  {unAssignedDocuments &&
    unAssignedDocuments.map((doc) => (
      <option key={"Unas" + doc.id} value={doc.id}>
        {doc.name}
      </option>
    ))}
  </>
  )
  }
    </>
):( 
  <>

  </>
)} */}

              {unAssignedDocuments && selectedDocuments && (
                <>
                  {unAssignedDocuments.length === 0 &&
                  selectedDocuments.length === 0 ? (
                    <>
                      <option value="no-documents">
                        No available documents
                      </option>
                    </>
                  ) : (
                    <>
                      {selectedDocuments &&
                        selectedDocuments.map((doc) => (
                          <option key={"SelectedDoc" + doc.id} value={doc.id}>
                            {doc.name}
                          </option>
                        ))}
                      {unAssignedDocuments &&
                        unAssignedDocuments.map((doc) => (
                          <option key={"Unas" + doc.id} value={doc.id}>
                            {doc.name}
                          </option>
                        ))}
                    </>
                  )}
                </>
              )}
            </select>
          </div>

          <div className="file-upload" style={{ marginLeft: "30px" }}>
            <h2>Product Image</h2>
            <input
              name="image"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleFileChange}
            />
            {/* Display the selected image */}
            {selectedImage && (
              <>
                <img
                  src={URL.createObjectURL(selectedImage[0])}
                  alt="Selected Product"
                  style={{ maxWidth: "200px" }}
                />
                <button
                  className=""
                  onClick={() => {
                    setSelectedImage(null);
                    clearInputField();
                  }}
                >
                  Remove Image
                </button>
              </>
            )}
          </div>

          <br></br>

          <button
            type="submit"
            style={{
              cursor: "pointer",
              width: "200px",
              marginLeft: "50%",
              height: "38px",
              background: "#005668",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              textalign: "center",
              fontSize: "medium",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default AddProducts;
