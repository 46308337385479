import api from "../api";

export const EditProductToDB = async (updatedData) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";

    await api.post(`/api/editproduct/${updatedData.id}`, updatedData);
    return "success";
  } catch (error) {
    console.error("An error occurred while editing the product:", error);
    return "failure";
  }
};
