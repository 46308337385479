import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../api";
import { useUserContext } from "../UserContext";
import Forgot from "./ForgotPassword";
import Lottie from "react-lottie";
import animationData from "./animation.json";
import "./LoginPage.css";
const LoginPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState("");
  const navigate = useNavigate();
  const { updateUserRole, updateUserName } = useUserContext();
  const [showForgot, setShowForgot] = useState(false);

  const handleForgotClick = () => {
    setShowForgot(true);
  };

  const handleforgotoff = () => {
    setShowForgot(false);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await api.post("api/login", { email, password });
      //console.log(response.data.message);
      //console.log(response.data);

      if (response.data.message === "Admin logged in successfully") {
        //console.log(response.data.message);
        seterror("");
        // console.log(response.data.user.name);
        updateUserName(response.data.user.name);
        localStorage.setItem("username", response.data.user.name);
        localStorage.setItem("is_admin", response.data.user.is_admin);
        localStorage.setItem("userid", response.data.user.id);
        localStorage.setItem("useremail", response.data.user.email);

        const token = response.data.token;
        localStorage.setItem("token", token);
        updateUserRole("admin");
        navigate("/admin", { replace: true });
      } else if (response.data.message === "User logged in successfully") {
        updateUserRole("user");
        updateUserName(response.data.user.name);
        localStorage.setItem("useremail", response.data.user.email);
        localStorage.setItem("username", response.data.user.name);
        localStorage.setItem("useremail", response.data.user.email);
        localStorage.setItem("userid", response.data.user.id);

        const token = response.data.token;
        localStorage.setItem("token", token);
        navigate("/user", { replace: true });
      }
    } catch (error) {
      console.error(error);
      // seterror("!!! NOT A VALID LOGIN !!!");
      seterror(
        <div
          style={{
            backgroundColor: "#F1CF8726",
            color: " #FFD98F",
            height: "40px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-around",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          Error! There seems to be an error, Please try again
        </div>
      );
    }
  };

  return (
    <>
      <div>
        {showForgot ? (
          <Forgot handleforgotoff={handleforgotoff} />
        ) : (
          <Container>
            <LeftSide>
              <Logo src="../logo.png" alt="Logo" />
              <Title>AERIAL</Title>
              <Copyright>By Intelly Digital Solutions</Copyright>
              <form onSubmit={handleLogin} style={{ marginTop: "20px" }}>
                <InputField
                  type="email"
                  placeholder="Email"
                  name="email"
                  autoComplete="username"
                  value={email}
                  onChange={(e) => {
                    seterror("");
                    setEmail(e.target.value);
                  }}
                  required
                />
                <InputField
                  type="password"
                  placeholder="Password"
                  name="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => {
                    seterror("");
                    setPassword(e.target.value);
                  }}
                  required
                />
                <button
                  type="submit"
                  style={{
                    width: "60%",
                    height: "60px",
                    margin: "0px auto 20px",
                    borderRadius: "5px",
                    border: "2px solid #fff",
                    color: "#fff",
                    fontWeight: "bold",
                    textAlign: "center",
                    lineHeight: "60px",
                    cursor: "pointer",
                    background: "transparent",
                  }}
                >
                  Login
                </button>
              </form>
              <div style={{ color: "red" }}> {error}</div>
              <ForgotPassword onClick={handleForgotClick}>
                Forgot password?
              </ForgotPassword>
            </LeftSide>
            <RightSide className="lottie-animation">
              <Lottie options={defaultOptions} />
            </RightSide>
          </Container>
        )}
      </div>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1e1e1e;
  overflow: hidden;
`;

const LeftSide = styled.div`
  flex: 1;
  padding: 3%;
  margin: 7vh 10vh 5vh 5vh;
  height: 60%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled.img`
  max-width: 450px;
  max-height: 125px;
  padding: 5px;
  width: 100%;
  min-height: 20px;
  margin: 0 auto;
`;

const Title = styled.div`
  text-align: center;
  font-size: clamp(10px, 2vw, 36px);
  color: white;
  font-family: "Inter", sans-serif;
`;

const InputField = styled.input`
  width: 65%;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ForgotPassword = styled.div`
  text-align: center;
  font-size: 2vh;
  color: #888;
  cursor: pointer;
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  @media (max-width: 767px) {
    flex: 0;
    display: none;
  }
`;
const Copyright = styled.div`
  text-align: center;
  font-size: clamp(10px, 1vw, 25px);
  color: #888;
  font-family: "Inter", sans-serif;
`;

export default LoginPage;
