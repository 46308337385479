import React from "react";
const DepartmentsDropdown = ({
  selectedLocation,
  departments,
  selectDepartment,
  setSelectDepartment,
}) => {
  const selectedLoc = Number(selectedLocation);
  const filteredDepartments = departments.filter(
    (department) => department.location_id === selectedLoc
  );
  return (
    <div className="dropdown-container">
      <label
        style={{ marginLeft: "30px", width: "180px", marginRight: "115px" }}
        htmlFor="departmentsDropdown"
      >
        Department
      </label>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "250px",
        }}
      >
        <select
          style={{
            backgroundColor: "#005668",
            fontSize: "large",
            color: "rgb(242, 242, 242)",
            height: "50px",
            width: "250px",
            borderRadius: "12px",
            border: "none",
            appearance: "none",
            padding: "0.5rem 1rem",
            cursor: "pointer",
          }}
          id="departmentsDropdown"
          value={selectDepartment}
          onChange={(e) => setSelectDepartment(e.target.value)}
          required
        >
          <option value=""></option>
          {filteredDepartments.map((department) => (
            <option
              style={{ color: "rgb(242, 242, 242)" }}
              key={department.id}
              value={department.id}
            >
              {department.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DepartmentsDropdown;
