import api from "../api";

export const AddProductsToDB = async (formDataWithFiles) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await api.post("/api/addproducts", formDataWithFiles);
    // If the addition is successful, return "success"
    return "success";
  } catch (error) {
    console.error("An error occurred while adding products:", error);
    // If an error occurs during the addition process, return "failure"
    return "failure";
  }
};
