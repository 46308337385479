import api from "../api";

export const FogotPasswordNewPass = async (email, code, password) => {
  try {
    const formatData = {
      email: email,
      password: password,
      token: code,
    };

    await api.post("/api/reqnewpassconfirm", formatData);
    return "success";
  } catch (error) {
    console.error("An error occurred while resetting the password:", error);
    return "failure";
  }
};
