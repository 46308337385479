import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";
import Select from "react-select";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    width: "100%",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 9999,
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

Modal.setAppElement("#root");

const EditUserModal = ({ isOpen, onRequestClose, UserData, onSave }) => {
  const initialFormData = {
    id: "",
    name: "",
    email: "",
    department_ids: [],
    password: "",
    isadmin: 0,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [passwordError, setPasswordError] = useState("");
  const [isadminuser, setIsadminUser] = useState(0);

  // When We Get UserData For Edit
  useEffect(() => {
    if (UserData) {
      setIsadminUser(UserData.is_admin);
      setSelectedLocationId(null);
      if (UserData.department_permissions) {
        const departmentIds = UserData.department_permissions.map(
          (permission) => permission.department_id
        );
        setFormData({
          id: UserData.id,
          name: UserData.name || "",
          email: UserData.email || "",
          department_ids: departmentIds || "",
          isadmin: UserData.is_admin,
        });
      } else {
        setIsadminUser(UserData.is_admin);
        setFormData({
          id: UserData.id,
          name: UserData.name || "",
          email: UserData.email || "",
          department_ids: "",
          isadmin: UserData.is_admin,
        });
      }

      console.log(UserData.is_admin, "UserData.isadmin");

      fetchAllData();
    }
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        const deptdata = await fetchDepartments();
        setLocations(locdata);
        setDepartments(deptdata);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [UserData]);

  // When User Selects New Location
  useEffect(() => {
    const filterDep = departments.filter(
      (department) => department.location_id === Number(selectedLocationId)
    );
    setFilteredDepartments(filterDep);
    // eslint-disable-next-line
  }, [selectedLocationId]);

  // When CheckBox is Changed
  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        department_ids: [...formData.department_ids, Number(value)],
      });
    } else {
      setFormData({
        ...formData,
        department_ids: formData.department_ids.filter(
          (id) => id !== Number(value)
        ),
      });
    }
  };
  const handleChangeLoc = (selectedOption) => {
    if (!selectedOption) {
      // Handle case when no option is selected
      setFilteredDepartments([]);
      setSelectedLocationId(""); // Assuming setSelectedLocationId sets the selected location id
      return;
    }
    setSelectedLocationId(selectedOption.value); // Assuming setSelectedLocationId sets the selected location id
  };
  // When Location is Changed
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "email") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "password") {
      setFormData({
        ...formData,
        [name]: value,
      });
      if (value.length < 8 && value.length > 0) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        setPasswordError("");
      }
    }
  };

  // When Saving
  const handleSubmit = () => {
    // Check if the password meets the minimum length requirement
    if (formData.password) {
      if (formData.password.length < 8 && formData.password.length > 0) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        // No password error, proceed with saving
        setPasswordError("");
        onSave(formData);
        onRequestClose();
        // console.log(formData.department_ids);
      }
    } else {
      onSave(formData);
      onRequestClose();
      // console.log(formData.department_ids);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit User"
    >
      {/* ///////////////////////////////////////// */}

      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      ></div>
      <div
        className=""
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            PADDING: "20px",
            width: "100%",
          }}
        >
          <h2 style={{ marginLeft: "30px" }} htmlFor="">
            User Details
          </h2>

          <div
            className="city-state-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="city-section"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
              }}
            >
              <div style={{ marginLeft: "30px", width: "50px" }}>Name </div>
              <input
                type="text"
                name="name"
                value={formData.name || ""}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
            <div
              className="state-section"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
              }}
            >
              <div style={{ width: " 50px" }}>Email </div>
              <input
                type="email"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}
                autoComplete="username"
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
          </div>
          <br></br>
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="zip-section"
              style={{
                flexGrow: "1",
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                flexDirection: "row",
                alignContent: "stretch",
              }}
            >
              <div style={{ marginLeft: "30px", width: "115px" }}>
                {" "}
                Change Password{" "}
              </div>
              <input
                type="password"
                name="password"
                value={formData.password || ""}
                onChange={handleChange}
                autoComplete="current-password"
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
              />
              {passwordError && (
                <p style={{ color: "red", margin: 0 }}>{passwordError}</p>
              )}
            </div>
          </div>

          <br></br>
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          ></div>

          {isadminuser === 0 ? (
            <>
              <br></br>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  alignContent: "stretch",
                }}
              >
                <h2 style={{}}>Permissions</h2>
                <label
                  style={{
                    fontSize: "large",
                    fontWeight: "bold",
                    width: "175px",
                  }}
                >
                  Location:
                  <Select
                    style={{ with: "175px" }}
                    name="location_id"
                    onChange={handleChangeLoc}
                    options={locations.map((location) => ({
                      value: location.id,
                      label: location.store_name,
                    }))}
                  ></Select>
                </label>
              </div>
              <br></br>
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "large",
                  marginLeft: "15px",
                  display: "flex",
                  flexFlow: "wrap",
                  alignItems: "center",
                  placeContent: "center",
                  maxWidth: "95%",
                  width: "auto",
                  maxHeight: "200px",
                  scrollbarWidth: "none",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignContent: "center",
                  overflow: "scroll",
                  justifyContent: "flex-start",
                }}
              >
                Department:
                {filteredDepartments.map((department) => (
                  <label
                    key={department.id}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="department_ids"
                      value={department.id}
                      checked={formData.department_ids.includes(department.id)}
                      onChange={handleCheckboxChange}
                    />
                    {department.name}
                  </label>
                ))}
              </label>
            </>
          ) : (
            <>
              <div
                style={{
                  marginLeft: "25%",
                  fontSize: "x-large",
                  marginTop: "29px",
                  marginBottom: "-50px",
                }}
              >
                This is Admin User
              </div>
            </>
          )}

          <br />
          <br />

          <button
            type="submit"
            style={{
              cursor: "pointer",
              width: "200px",
              marginLeft: "25%",
              height: "38px",
              marginTop: "50px",
              background: "#005668",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              textalign: "center",
              fontSize: "medium",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default EditUserModal;
