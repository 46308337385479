import api from "../api";

export const ImportProductsToDB = async (formData, locid, depid, catid) => {
  try {
    const myformData = new FormData();
    myformData.append(`files[0]`, formData);

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const response = await api.post(
      `/api/importproducts/${locid}/${depid}/${catid}`,
      myformData
    );

    if (response.status === 200) {
      return "success";
    } else {
      console.error("Import failed");
      return "failure";
    }
  } catch (error) {
    console.error(error);
    return "failure";
  }
};
