import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useUserContext } from "./UserContext";
import { useClickAway } from "react-use";
import { useEffect } from "react";
import HomeIcon from "./Icons/HomeIcon";
import FolderIcon from "./Icons/FolderIcon";
import TableIcon from "./Icons/TableIcon";
import SettingsIcon from "./Icons/SettingsIcon";
import LogoutIcon from "./Icons/LogoutIcon";
import ProfileIcon from "./Icons/ProfileIcon";

const Sidebar = () => {
  function formatTimeToAMPM(time) {
    const timeParts = time.split(":");
    let hours = parseInt(timeParts[0], 10);
    const minutes = timeParts[1];

    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;

    return `${hours}:${minutes} ${ampm}`;
  }

  const { userRole, logout, extraData, showExtraData, setShowExtraD } =
    useUserContext();
  const location = useLocation();
  const homeLink = userRole === "admin" ? "/admin" : "/user";
  const isHome = location.pathname === homeLink;
  const gotData =
    extraData !== null &&
    extraData !== undefined &&
    ((typeof extraData === "object" && Object.keys(extraData).length > 0) ||
      (Array.isArray(extraData) && extraData.length > 0));

  const [showSubMenu, setShowSubMenu] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setShowSubMenu(false);
  });
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  useEffect(() => {}, [isHome]);
  return (
    <div className="mySideBar">
      <Container ref={ref}>
        <Logo>
          <img src="/image 2.svg" alt="Logo" width="140%" />
        </Logo>
        <Menu>
          <Link to={homeLink}>
            {" "}
            <MenuItem
              onClick={() => {
                setShowSubMenu(false);
              }}
            >
              <HomeIcon size="40px" fill="white" />
            </MenuItem>
          </Link>
          <Link to="/table">
            <MenuItem
              onClick={() => {
                setShowSubMenu(false);
              }}
            >
              <TableIcon size="40px" fill="white" />
            </MenuItem>
          </Link>
          <Link to="/folder">
            <MenuItem
              onClick={() => {
                setShowSubMenu(false);
              }}
            >
              <FolderIcon size="40px" fill="white" />
            </MenuItem>
          </Link>
          <Link to="/profileedit">
            <MenuItem
              onClick={() => {
                setShowSubMenu(false);
              }}
            >
              <ProfileIcon size="40px" fill="white" />
            </MenuItem>
          </Link>
          {userRole === "admin" && (
            <MenuItem onClick={toggleSubMenu}>
              <SettingsIcon size="40px" fill="white" />
            </MenuItem>
          )}
          {showSubMenu && userRole === "admin" && (
            <SubMenu>
              <Link to="/admin/locations">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Locations
                </SubMenuItem>
              </Link>
              <Link to="/admin/departments">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Departments
                </SubMenuItem>
              </Link>
              <Link to="/admin/categories">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Categories
                </SubMenuItem>
              </Link>
              <Link to="/admin/products">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Products
                </SubMenuItem>
              </Link>
              <Link to="/admin/colors">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Colors
                </SubMenuItem>
              </Link>
              <Link to="/admin/users">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Users
                </SubMenuItem>
              </Link>
              <Link to="/admin/importdata">
                <SubMenuItem
                  onClick={() => {
                    setShowSubMenu(false);
                  }}
                >
                  Import Data
                </SubMenuItem>
              </Link>
            </SubMenu>
          )}
        </Menu>
        <MenuItem onClick={logout}>
          <LogoutIcon
            onClick={() => {
              setShowSubMenu(false);
            }}
            size="40px"
            fill="white"
          />
        </MenuItem>
      </Container>

      {isHome && gotData && !showSubMenu && showExtraData && (
        <ExtraInfo>
          <TabsParentDiv>
            <TabsRowStyle>
              <FirstTAB>
                <FirstTABText>Retail Code</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>{extraData.retail_code}</SecondTabText>
              </SecondTab>
            </TabsRowStyle>
            <TabsRowStyle>
              <FirstTAB>
                <FirstTABText>Store Manager</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>{extraData.location_manager_name}</SecondTabText>
              </SecondTab>
            </TabsRowStyle>

            <TabsRowStyle>
              <FirstTAB>
                <FirstTABText>Store Manager #</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>
                  {extraData.location_manager_phone}
                </SecondTabText>
              </SecondTab>
            </TabsRowStyle>

            <TabsRowStyle>
              <FirstTAB>
                <FirstTABText>Area Manager</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>{extraData.area_manager_name}</SecondTabText>
              </SecondTab>
            </TabsRowStyle>

            <TabsRowStyle>
              <FirstTAB>
                <FirstTABText>Area Manager #</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>{extraData.area_manager_phone}</SecondTabText>
              </SecondTab>
            </TabsRowStyle>

            <TabsRowStyle>
              {/* <p>address: {extraData.address}</p> */}
              <FirstTAB>
                <FirstTABText>Monday - Friday</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>
                  {extraData.mon_to_fri_opening && extraData.mon_to_fri_closing
                    ? formatTimeToAMPM(extraData.mon_to_fri_opening) +
                      " - " +
                      formatTimeToAMPM(extraData.mon_to_fri_closing)
                    : ""}
                </SecondTabText>
              </SecondTab>
            </TabsRowStyle>

            <TabsRowStyle>
              <FirstTAB>
                <FirstTABText>Saturday - Sunday</FirstTABText>
              </FirstTAB>
              <SecondTab>
                <SecondTabText>
                  {extraData.sat_to_sun_opening && extraData.sat_to_sun_closing
                    ? formatTimeToAMPM(extraData.sat_to_sun_opening) +
                      " - " +
                      formatTimeToAMPM(extraData.sat_to_sun_closing)
                    : ""}
                </SecondTabText>
              </SecondTab>
            </TabsRowStyle>
            {/* 
          <p style={{fontSize:"x-small",marginLeft:"5px"}}>sat_to_sun_opening</p>
          <p style={{fontSize:"x-small",marginLeft:"5px"}}>{extraData.sat_to_sun_opening}</p>

          <p style={{fontSize:"x-small",marginLeft:"5px"}}>sat_to_sun_closing</p>
          <p style={{fontSize:"x-small",marginLeft:"5px"}}>{extraData.sat_to_sun_closing}</p> */}
          </TabsParentDiv>

          <HideP
            id="hideLink"
            onClick={() => {
              setShowExtraD(false);
            }}
          >
            Hide
          </HideP>
        </ExtraInfo>
      )}
    </div>
  );
};

const HideP = styled.p`
  cursor: pointer;
  text-decoration: underline;
  color: rgb(16, 89, 106);
  font-weight: 600;
`;

const FirstTABText = styled.div`
  margin-left: 12px;
  margin-top: 10px;
  font-size: small;
`;

const SecondTabText = styled.div`
  margin-left: 12px;
  margin-top: 10px;
  font-size: small;
`;
const TabsParentDiv = styled.div``;
const TabsRowStyle = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin-bottom: 2px;
  text-align: left;
  height: 35px;
`;

const FirstTAB = styled.div`
  color: white;
  width: 48%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  text-align: left;

  background: rgb(16, 89, 106);
`;

const SecondTab = styled.div`
  color: black;
  background: rgb(238, 248, 249);
  width: 52%;
  text-align: center;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 75px;
  top: 37vh;
  right: calc(100% + 10px);
  background-color: #1e1e1e;
  color: white;
  min-width: 200px;
  align-items: center;
  z-index: 3;
  text-decoration: none; /* Remove underline */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px 0;
`;

const ExtraInfo = styled.div`
  text-align: center;
  color: black;
  position: absolute;
  left: 5%;
  width: 25%;
  top: 35%;
  height: auto;
  margin-left: 10px;
`;

const SubMenuItem = styled.div`
  display: flex;
  color: white;
  max-width: 200px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none; /* Remove underline */
  /* transition: background-color 0.2s;*/

  &:hover {
    display: block;
    position: relative;
    width: -webkit-fill-available;
    font-size: large;
    background-color: #fff;
    color: black;
    max-width: 180px;
    min-width: 180px;
    text-align: center;
    text-decoration: none;
  }
`;

const Container = styled.div`
  width: 70px;
  height: 100%;
  background-color: #1E1E1E;
  color: white;
  text-decoration: none; /* Remove underline */
  display: flex;
  flex-direction: column;

@media (max-width: 1240px): {
      position: 'absolute !important';
      height: 116vh;

    },
  

`;

const Logo = styled.div`
  padding: 20px;
  text-align: center;
  img {
    max-width: 140%;
    height: auto;
  }
`;

const Menu = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-decoration: none; /* Remove underline */
  align-items: center;
  margin-top: 50px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: #fff;
    color: black;
    svg {
      stroke: black;
    }
    svg path {
      stroke: black;
    }
    svg circle {
      stroke: black;
    }
  }
`;

// const Logout = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 15px;
//   cursor: pointer;
//   transition: background-color 0.2s;
//   &:hover {
//     background-color: #fff;
//     color: black;
//   }
// `;

export default Sidebar;
