import api from "../api";

export const EditUserToDB = async (updatedData) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.put(`/api/user/${updatedData.id}`, updatedData);
    return "success";
  } catch (error) {
    console.error("Error editing user:", error);
    return "failure";
  }
};
