import api from "../api";

export const ImportLocationsToDB = async (formData) => {
  try {
    const myformData = new FormData();
    myformData.append(`files[0]`, formData);

    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const response = await api.post("/api/importlocations", myformData);

    if (response.status === 200) {
      return "success"; // Return "success" if locations were imported successfully
    } else {
      console.error("Import failed");
      return "failure"; // Return "failure" if the request fails
    }
  } catch (error) {
    console.error(error);
    return "failure"; // Return "failure" if an error occurs during the request
  }
};
