import React, { useState, useEffect } from "react";
import "../globalstyle.css";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";
import { fetchCategories } from "../FetchData/FetchCategories";
import ImportLocations from "../ImportPaages/ImportLocations";
import ImportDepartments from "../ImportPaages/ImportDepartments";
import ImportCategories from "../ImportPaages/ImportCategories";
import ImportProducts from "../ImportPaages/ImportProducts";

const ImportData = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const locData = await fetchLocations();
        const deptData = await fetchDepartments();
        const catData = await fetchCategories();
        setCategories(catData);
        setLocations(locData);
        setDepartments(deptData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllData();
  }, []);

  return (
    <div>
      <h2 className="welcomeText">Import</h2>
      <div className="importButtons" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        <button className="import-button" onClick={() => setSelectedOption("location")}>Import Locations</button>
        <button className="import-button" onClick={() => setSelectedOption("department")}>Import Departments</button>
        <button className="import-button" onClick={() => setSelectedOption("category")}>Import Categories</button>
        <button className="import-button" onClick={() => setSelectedOption("product")}>Import Products</button>
      </div>
      <br></br>
      {selectedOption === "location" && (
       <>
       <ImportLocations></ImportLocations>
       </>
      )}


      {selectedOption === "department" && (
        <>
     <ImportDepartments locations={locations}></ImportDepartments>
          </>
)}
{selectedOption === "category" && (
        <>
       <ImportCategories locations={locations} departments={departments}></ImportCategories>
        </>
    )}

      {selectedOption === "product" && (
<>
<ImportProducts locations={locations} departments={departments} categories={categories}></ImportProducts>
</>
      )}
    </div>
  );
};

export default ImportData;
