import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import "../globalstyle.css";
import { ImportDepartmentsToDB } from "../ImportToDB/ImportDepartmentsToDB";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import PopupMessage from "../popupmessage";

const ImportDepartments = ({ locations }) => {
  // console.log(locations);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  useEffect(() => {
    setSelectedFiles(null);
  }, [fileInputKey]); // Reset file input when the key changes

  useEffect(() => {
    setSelectedFiles(null);
  }, []);

  const handleLocationChange = (locationId) => {
    setSelectedLocation(locationId);
    setSelectedFiles(null);
    // console.log(locationId);
    // Increment the key to reset the file input
    setFileInputKey((prevKey) => prevKey + 1);
  };
  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };
  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = selectedFiles[0];
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const sheetHeaders = [];
          for (let col in firstSheet) {
            if (/^[A-Z]+\d+$/.test(col)) {
              const rowIndex = Number(col.match(/\d+/)[0]);
              if (rowIndex === 1) {
                sheetHeaders.push(firstSheet[col].v);
              }
            }
          }

          //  console.log('Headers in the Excel file:', sheetHeaders);
          const expectedColumns = ["name", "description"];
          const missingColumns = expectedColumns.filter(
            (header) => !sheetHeaders.includes(header)
          );
          if (missingColumns.length === 0) {
            // console.log('Excel file structure is valid');
            try {
              ImportDepartmentsToDB(selectedFiles[0], selectedLocation).then(
                (st) => {
                  // console.log("OOOO");
                  if (st && st === "success") {
                    showPopupfunc("success", "Departments Imported");
                  } else if (st && st === "failure") {
                    showPopupfunc("failure", "Departments Import Error");
                  }
                  setSelectedFiles(null);
                  setFileInputKey((prevKey) => prevKey + 1);
                }
              );
            } catch (error) {
              console.log(error, "Error");
              showPopupfunc("failure", "Departments Import Error");

              setSelectedFiles(null);
              setFileInputKey((prevKey) => prevKey + 1);
            }
          } else {
            //console.error('Missing columns:', missingColumns);
            showPopupfunc("failure", "Missing columns");
          }
        } catch (error) {
          showPopupfunc("failure", "Error reading Excel");

          console.error("Error reading Excel file:", error.message);
        }
      };
    }
    reader.readAsArrayBuffer(file);
    // console.log("OOO");
    // console.log(selectedFiles[0]);
  };
  return (
    <>
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      <div
        style={{
          margin: "31px",
          textAlign: "center",
          width: "fit-content",
          background: "#005668",
          fontSize: "xx-large",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <a
          href={"https://aerial.intelly.ca/departments%20-%20Sample.xlsx"}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          Download Sample For Departments Import
        </a>
      </div>
      <br></br>
      {locations.length > 0 ? (
        <div>
          <LocationsDropdown
            locations={locations}
            selectLocation={selectedLocation}
            setSelectLocation={handleLocationChange}
          />
          <br></br>
          {selectedLocation && (
            <>
              <input
                key={fileInputKey}
                type="file"
                accept=".xlsx"
                onChange={(e) => {
                  setSelectedFiles(e.target.files);
                }}
                style={{ fontSize: "22px", padding: "10px" }}
              />
              {selectedFiles ? (
                <>
                  <button
                    type="button"
                    className="importButton"
                    onClick={handleFileUpload}
                    style={{ fontSize: "18px", padding: "12px" }}
                  >
                    Import
                  </button>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      ) : (
        <div>No Locations</div>
      )}
    </>
  );
};
export default ImportDepartments;
