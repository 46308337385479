import api from "../api";
export const FogotPasswordReq = async (email) => {
  try {
    const formatData = {
      email: email,
    };
    const response = await api.post("/api/reqnewpass", formatData);
    return response.data.message;
  } catch (error) {
    console.error(error);
  }
};
