import React, { useState, useEffect } from "react";
import "../globalstyle.css";
import { FetchUnAssignedDocs } from "../FetchData/FetchUnAssignedDocs";

const EditLocation = (props) => {
  const { onRequestClose, LocationData, onSave } = props;

  // console.log(LocationData);
  const [formData, setFormData] = useState({
    id: LocationData.id,
    retail_code: LocationData.retail_code,
    store_name: LocationData.store_name,
    address: LocationData.address,
    city: LocationData.city,
    state: LocationData.state,
    zip_code: LocationData.zip_code,
    country: LocationData.country,
    location_manager_name: LocationData.location_manager_name,
    location_manager_phone: LocationData.location_manager_phone,
    area_manager_name: LocationData.area_manager_name,
    area_manager_phone: LocationData.area_manager_phone,
    mon_to_fri_opening: LocationData.mon_to_fri_opening,
    mon_to_fri_closing: LocationData.mon_to_fri_closing,
    sat_to_sun_opening: LocationData.sat_to_sun_opening,
    sat_to_sun_closing: LocationData.sat_to_sun_closing,
    locationDocuments: LocationData.documents || [],
  });

  const [unAssignedDocuments, setUnAssignedDocuments] = useState(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [mon_to_fri_open_hours, setMon_to_fri_open_hours] = useState("");
  const [mon_to_fri_open_minutes, setMon_to_fri_open_minutes] = useState("");
  const [mon_to_fri_open_period, setMon_to_fri_open_period] = useState("AM");

  const [mon_to_fri_close_hours, setMon_to_fri_close_hours] = useState("AM");
  const [mon_to_fri_close_minutes, setMon_to_fri_close_minutes] = useState("");
  const [mon_to_fri_close_period, setMon_to_fri_close_period] = useState("");

  const [sat_to_sun_open_period, setSat_to_sun_open_period] = useState("AM");
  const [sat_to_sun_open_hours, setSat_to_sun_open_hours] = useState("");
  const [sat_to_sun_open_minutes, setSat_to_sun_open_minutes] = useState("");

  const [sat_to_sun_close_period, setSat_to_sun_close_period] = useState("AM");
  const [sat_to_sun_close_hours, setSat_to_sun_close_hours] = useState("");
  const [sat_to_sun_close_minutes, setSat_to_sun_close_minutes] = useState("");

  useEffect(() => {
    const mon_to_fri_openingdata = formatTimeToAMPM(
      formData.mon_to_fri_opening
    );
    const mon_to_fri_closingdata = formatTimeToAMPM(
      formData.mon_to_fri_closing
    );
    const sat_to_sun_openingdata = formatTimeToAMPM(
      formData.sat_to_sun_opening
    );
    const sat_to_sun_closingdata = formatTimeToAMPM(
      formData.sat_to_sun_closing
    );
    setMon_to_fri_open_hours(mon_to_fri_openingdata.hours);
    setMon_to_fri_open_minutes(mon_to_fri_openingdata.minutes);
    setMon_to_fri_open_period(mon_to_fri_openingdata.period);

    setMon_to_fri_close_hours(mon_to_fri_closingdata.hours);
    setMon_to_fri_close_minutes(mon_to_fri_closingdata.minutes);
    setMon_to_fri_close_period(mon_to_fri_closingdata.period);

    setSat_to_sun_open_hours(sat_to_sun_openingdata.hours);
    setSat_to_sun_open_minutes(sat_to_sun_openingdata.minutes);
    setSat_to_sun_open_period(sat_to_sun_openingdata.period);

    setSat_to_sun_close_hours(sat_to_sun_closingdata.hours);
    setSat_to_sun_close_minutes(sat_to_sun_closingdata.minutes);
    setSat_to_sun_close_period(sat_to_sun_closingdata.period);

    async function fetchAllData() {
      try {
        const data = await FetchUnAssignedDocs();
        setUnAssignedDocuments(data.data);
        if (formData.locationDocuments.length !== 0) {
          const selectedDocumentsObject = formData.locationDocuments.reduce(
            (result, doc) => {
              result[doc.id] = doc.name;
              return result;
            },
            {}
          );
          const selectedDocumentsArray = Object.entries(
            selectedDocumentsObject
          ).map(([id, name]) => ({
            id: Number(id),
            name: name,
          }));
          setSelectedDocuments(selectedDocumentsArray);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (selectedDocuments) {
      //console.log(selectedDocuments);
      setFormData({ ...formData, locationDocuments: selectedDocuments });
    }
    // eslint-disable-next-line
  }, [selectedDocuments]);

  const handleFileChange = (e) => {
    const selectedDocumentId = parseInt(e.target.value, 10);
    if (
      isNaN(selectedDocumentId) &&
      selectedDocuments !== null &&
      selectedDocuments.length > 0
    ) {
      const lastSelectedDoc = selectedDocuments[selectedDocuments.length - 1];
      setSelectedDocuments((previousState) =>
        previousState.filter((document) => document.id !== lastSelectedDoc.id)
      );
      const myObj = {
        id: lastSelectedDoc.id,
        name: lastSelectedDoc.name,
      };
      setUnAssignedDocuments((prevSelected) => [...prevSelected, myObj]);
    } else if (selectedDocuments.some((doc) => doc.id === selectedDocumentId)) {
      const selectedDoc = selectedDocuments.find(
        (doc) => doc.id === selectedDocumentId
      );
      if (selectedDoc) {
        setSelectedDocuments((previousState) =>
          previousState.filter((document) => document.id !== selectedDocumentId)
        );
        const myObj = {
          id: selectedDoc.id,
          name: selectedDoc.name,
        };
        setUnAssignedDocuments((prevSelected) => [...prevSelected, myObj]);
      }
    } else if (
      unAssignedDocuments.some((doc) => doc.id === selectedDocumentId)
    ) {
      const selectedDoc = unAssignedDocuments.find(
        (doc) => doc.id === selectedDocumentId
      );
      if (selectedDoc) {
        setUnAssignedDocuments((previousState) =>
          previousState.filter((document) => document.id !== selectedDocumentId)
        );
        const myObj = {
          id: Number(selectedDoc.id),
          name: selectedDoc.name,
        };
        setSelectedDocuments((prevSelected) => [...prevSelected, myObj]);
      }
    } else {
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    //mon to friday open
    if (name === "mon_to_fri_open_period") {
      setMon_to_fri_open_period(value);
    } else if (name === "mon_to_fri_open_hours") {
      setMon_to_fri_open_hours(value);
    } else if (name === "mon_to_fri_open_minutes") {
      setMon_to_fri_open_minutes(value);
    }
    //mon to friday close
    else if (name === "mon_to_fri_close_period") {
      setMon_to_fri_close_period(value);
    } else if (name === "mon_to_fri_close_hours") {
      setMon_to_fri_close_hours(value);
    } else if (name === "mon_to_fri_close_minutes") {
      setMon_to_fri_close_minutes(value);
    }

    //sat to sun open
    else if (name === "sat_to_sun_open_period") {
      setSat_to_sun_open_period(value);
    } else if (name === "sat_to_sun_open_hours") {
      setSat_to_sun_open_hours(value);
    } else if (name === "sat_to_sun_open_minutes") {
      setSat_to_sun_open_minutes(value);
    }

    //sat to sun close
    else if (name === "sat_to_sun_close_period") {
      setSat_to_sun_close_period(value);
    } else if (name === "sat_to_sun_close_hours") {
      setSat_to_sun_close_hours(value);
    } else if (name === "sat_to_sun_close_minutes") {
      setSat_to_sun_close_minutes(value);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedFormData = {
      ...formData,
      mon_to_fri_opening: formatTimeTo24Hrs(
        mon_to_fri_open_hours,
        mon_to_fri_open_minutes,
        mon_to_fri_open_period
      ),
      mon_to_fri_closing: formatTimeTo24Hrs(
        mon_to_fri_close_hours,
        mon_to_fri_close_minutes,
        mon_to_fri_close_period
      ),
      sat_to_sun_opening: formatTimeTo24Hrs(
        sat_to_sun_open_hours,
        sat_to_sun_open_minutes,
        sat_to_sun_open_period
      ),
      sat_to_sun_closing: formatTimeTo24Hrs(
        sat_to_sun_close_hours,
        sat_to_sun_close_minutes,
        sat_to_sun_close_period
      ),
    };
    const documentIds = Array.isArray(formData.locationDocuments)
      ? formData.locationDocuments.map((doc) => doc.id)
      : [];

    //console.log(documentIds);
    const updatedDataToSend = {
      ...formattedFormData,
      locationDocuments: documentIds,
    };

    try {
      onSave(updatedDataToSend);
    } catch (error) {
      console.error(error);
    }
  };

  const formatTimeTo24Hrs = (hours, minutes, period) => {
    let formattedHours = parseInt(hours, 10);
    if (period === "PM" && formattedHours !== 12) {
      formattedHours += 12;
    } else if (period === "AM" && formattedHours === 12) {
      formattedHours = 0;
    }
    const formattedMinutes = minutes === undefined ? "00" : minutes;
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${formattedMinutes}:00`;
    // console.log(formattedTime);
    return formattedTime;
  };

  const formatTimeToAMPM = (time) => {
    const [hours, minutes] = time.split(":");
    let period = "AM";
    let formattedHours = parseInt(hours, 10);

    if (formattedHours >= 12) {
      period = "PM";
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    } else if (formattedHours === 0) {
      formattedHours = 12;
    }

    const formattedMinutes = minutes.padStart(2, "0");
    const formattedHoursString = formattedHours.toString().padStart(2, "0");

    // Return an object with hours, minutes, and period
    return {
      hours: formattedHoursString,
      minutes: formattedMinutes,
      period: period,
    };
  };

  // Example usage:
  //   const timeIn24HourFormat = "14:30:00"; // Example time in 24-hour format with seconds
  //   const formattedTime = formatTimeToAMPM(timeIn24HourFormat);

  //   // Now, you can access individual components of the formatted time
  //   console.log(formattedTime.hours); // Outputs 2
  //   console.log(formattedTime.minutes); // Outputs "30"
  //   console.log(formattedTime.period); // Outputs "PM"

  return (
    <>
      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={onRequestClose}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">Edit Location</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            PADDING: "20px",
            width: "100%",
          }}
        >
          <div
            className="store-name"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "30px",
                width: "180px",
              }}
            >
              Retail Code{" "}
            </div>
            <input
              type="text"
              name="retail_code"
              value={formData.retail_code}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "30px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              required
            />
          </div>
          <div
            className="store-name"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "30px",
                width: "180px",
              }}
            >
              Store Name{" "}
            </div>
            <input
              type="text"
              name="store_name"
              value={formData.store_name}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "30px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              required
            />
          </div>
          <br></br>
          <div
            className="address"
            style={{
              display: "inline-flex",
              width: "100%",
              alignItems: "stretch",
            }}
          >
            <div style={{ marginLeft: "30px", width: "180px" }}>Address </div>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              style={{
                marginLeft: "10px",
                border: "none",
                width: "70%",
                height: "30px",
                marginBottom: "20px",
              }}
              required
            />
          </div>
          <br></br>
          <div
            className="city-state-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="city-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>City </div>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
            <div
              className="state-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: " 170px" }}>State </div>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
          </div>
          <br></br>
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="zip-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Zip Code{" "}
              </div>
              <input
                type="text"
                name="zip_code"
                value={formData.zip_code}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
                required
              />
            </div>
            <div
              className="country-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: "170px" }}>Country </div>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
                required
              />
            </div>
          </div>
          <br></br>
          <div
            className="manager-text-sec"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginLeft: "30px", marginBottom: "10px" }}>
              Location Manager Details{" "}
            </div>
            <div
              style={{ marginLeft: "30px", marginBottom: "10px", width: "50%" }}
            >
              Area Manager Details{" "}
            </div>
          </div>
          <div
            className="manager-names"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="loc-m-name"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>Name </div>
              <input
                type="text"
                name="location_manager_name"
                value={formData.location_manager_name}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "20px",
                }}
                required
              />
            </div>
            <div
              className="area-m-name"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: "170px" }}>Name </div>
              <input
                type="text"
                name="area_manager_name"
                value={formData.area_manager_name}
                onChange={handleChange}
                style={{
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "20px",
                }}
                required
              />
            </div>
          </div>
          <div
            className="manager-phones"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="loc-m-phone"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>Phone </div>
              <input
                type="text"
                name="location_manager_phone"
                value={formData.location_manager_phone}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  marginBottom: "15px",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
            <div
              className="area-m-phone"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: "170px" }}>Phone </div>
              <input
                type="text"
                name="area_manager_phone"
                value={formData.area_manager_phone}
                onChange={handleChange}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "15px",
                }}
                required
              />
            </div>
          </div>
          <div style={{ marginLeft: "30px" }}>Business Hours</div>
          <div
            className="business-hours"
            style={{
              display: "flex",
              marginTop: "15px",
              justifyContent: "space-between",
            }}
          >
            <div
              className="mon-fri-hours"
              style={{ marginLeft: "30px", width: "45%" }}
            >
              <label>Mon to Fri Hours</label>
              <br></br>
              <div className="mon-fri-open">
                <label>Open</label>
                {/* {console.log(mon_to_fri_open_hours,"(mon_to_fri_open_hours)}")} */}
                <select
                  name="mon_to_fri_open_hours"
                  onChange={handleChange}
                  value={mon_to_fri_open_hours}
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={(i + 1).toString().padStart(2, "0")}>
                      {(i + 1).toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  name="mon_to_fri_open_minutes"
                  onChange={handleChange}
                  value={mon_to_fri_open_minutes}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <select
                  name="mon_to_fri_open_period"
                  onChange={handleChange}
                  value={mon_to_fri_open_period}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              <br></br>
              <div className="mon-fri-close">
                <label>Close</label>
                <select
                  name="mon_to_fri_close_hours"
                  onChange={handleChange}
                  value={mon_to_fri_close_hours}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={(i + 1).toString().padStart(2, "0")}>
                      {(i + 1).toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  name="mon_to_fri_close_minutes"
                  onChange={handleChange}
                  value={mon_to_fri_close_minutes}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <select
                  name="mon_to_fri_close_period"
                  onChange={handleChange}
                  value={mon_to_fri_close_period}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
            <div
              className="sat-sun-hours"
              style={{ marginLeft: "20px", width: "50%" }}
            >
              <label>Sat to Sun Hours</label>
              <br></br>
              <div className="sat-sun-open">
                <label>Sat-Sun Open</label>
                <select
                  name="sat_to_sun_open_hours"
                  onChange={handleChange}
                  value={sat_to_sun_open_hours}
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={(i + 1).toString().padStart(2, "0")}>
                      {(i + 1).toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  name="sat_to_sun_open_minutes"
                  onChange={handleChange}
                  value={sat_to_sun_open_minutes}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <select
                  name="sat_to_sun_open_period"
                  onChange={handleChange}
                  value={sat_to_sun_open_period}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              <br></br>
              <div className="sat-sun-close">
                <label>Sat-Sun Close</label>
                <select
                  name="sat_to_sun_close_hours"
                  onChange={handleChange}
                  value={sat_to_sun_close_hours}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={(i + 1).toString().padStart(2, "0")}>
                      {(i + 1).toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  name="sat_to_sun_close_minutes"
                  onChange={handleChange}
                  value={sat_to_sun_close_minutes}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  {Array.from({ length: 60 }, (_, i) => (
                    <option key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
                <select
                  name="sat_to_sun_close_period"
                  onChange={handleChange}
                  value={sat_to_sun_close_period}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>
          <br></br>
          <h2 style={{ marginLeft: "20px" }}>Documents Assign</h2>
          <p style={{ marginLeft: "20px" }}>
            Hold CTRL + Click to deselect documents
          </p>

          <select
            name="selectedDocument"
            style={{ marginLeft: "20px" }}
            value={selectedDocuments.map((doc) => doc.id)}
            onChange={handleFileChange}
            multiple
            onClick={() => {
              if (selectedDocuments.length === 1) {
                const lastSelectedDoc =
                  selectedDocuments[selectedDocuments.length - 1];
                setSelectedDocuments((previousState) =>
                  previousState.filter(
                    (document) => document.id !== lastSelectedDoc.id
                  )
                );
                const myObj = {
                  id: lastSelectedDoc.id,
                  name: lastSelectedDoc.name,
                };
                setUnAssignedDocuments((prevSelected) => [
                  ...prevSelected,
                  myObj,
                ]);
              }
            }}
          >
            {/* {unAssignedDocuments && selectedDocuments ?( <>
    {unAssignedDocuments.length === 0 && selectedDocuments.length === 0 ?(<>
  <option value="no-documents">No available documents</option>
    </>):( 
      <>
      {selectedDocuments &&
  selectedDocuments.map((doc) => (
    <option key={"SelectedDoc" + doc.id} value={doc.id}>
      {doc.name}
    </option>
  ))}
  {unAssignedDocuments &&
    unAssignedDocuments.map((doc) => (
      <option key={"Unas" + doc.id} value={doc.id}>
        {doc.name}
      </option>
    ))}
  </>
  )
  }
    </>
):( 
  <>

  </>
)} */}

            {unAssignedDocuments && selectedDocuments && (
              <>
                {unAssignedDocuments.length === 0 &&
                selectedDocuments.length === 0 ? (
                  <>
                    <option value="no-documents">No available documents</option>
                  </>
                ) : (
                  <>
                    {selectedDocuments &&
                      selectedDocuments.map((doc) => (
                        <option key={"SelectedDoc" + doc.id} value={doc.id}>
                          {doc.name}
                        </option>
                      ))}
                    {unAssignedDocuments &&
                      unAssignedDocuments.map((doc) => (
                        <option key={"Unas" + doc.id} value={doc.id}>
                          {doc.name}
                        </option>
                      ))}
                  </>
                )}
              </>
            )}
          </select>
          <button
            type="submit"
            style={{
              cursor: "pointer",
              width: "200px",
              marginLeft: "50%",
              height: "38px",
              background: "#005668",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              textalign: "center",
              fontSize: "medium",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default EditLocation;
