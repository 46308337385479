import api from "../api";

export const AddUserToDB = async (formDataWithFiles) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    const response = await api.post("/api/user", formDataWithFiles);
    if (response.status === 201) {
      return "success";
    } else {
      return "failure";
    }
  } catch (error) {
    console.error(error);
    return "failure";
  }
};
