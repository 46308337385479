import api from "../api";

export const AddLocationsToDB = async (formData) => {
  try {
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await api.post("/api/locations", formData);
    return "success";
  } catch (error) {
    console.error("An error occurred while adding location:", error);
    return "failure";
  }
};
