// AddProducts.js
import React, { useState } from "react";
import "../globalstyle.css";
import { AddUserToDB } from "../AddToDB/AddUserToDB";
import PopupMessage from "../popupmessage";

const AddUsers = (props) => {
  const { toggleAddUserForm, setUserChangeFlag, userChangeFlag } = props;
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [popupMessage, setPopupMessage] = useState(""); // State to set the message content
  const userid = localStorage.getItem("userid");

  const [formData, setFormData] = useState({
    name: undefined,
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    isAdmin: 0,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPopupMessage("Passwords do not match");
      setShowPopup(true);
      return;
    }
    try {
      const formDataWithFiles = new FormData();
      formDataWithFiles.append("name", formData.name);
      formDataWithFiles.append("email", formData.email);
      formDataWithFiles.append("password", formData.password);
      if (formData.isAdmin === true) {
        formDataWithFiles.append("is_admin", 1);
      } else {
        formDataWithFiles.append("is_admin", 0);
      }
      const st = await AddUserToDB(formDataWithFiles);
      toggleAddUserForm(st);
      setUserChangeFlag(!userChangeFlag);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {showPopup && (
        <PopupMessage
          type="failure"
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      <div
        className="add-top-sec"
        style={{
          display: "inline-flex",
          alignItems: "baseline",
          marginTop: "0px",
        }}
      >
        <button
          title="Back"
          onClick={toggleAddUserForm}
          style={{
            cursor: "pointer",
            marginRight: "100px",
            background: "#005668",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            textAlign: "center",
            fontSize: "x-large",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          ←
        </button>

        <h2 className="welcomeTextAdd">Add New User</h2>
      </div>
      <div
        className="myFormAddParent"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <form
          className="myFormAdd"
          onSubmit={handleSubmit}
          style={{
            fontFamily: "inter",
            backgroundColor: "#f2f2f2",
            PADDING: "20px",
            width: "100%",
          }}
        >
          <h2 style={{ marginLeft: "30px" }} htmlFor="">
            User Details
          </h2>

          <div
            className="city-state-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="city-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>Name </div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
            <div
              className="state-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: " 170px" }}>Email </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="username"
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                }}
                required
              />
            </div>
          </div>
          <br></br>
          <div
            className="zip-country-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="zip-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Password{" "}
              </div>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="current-password"
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
                required
              />
            </div>
            <div
              className="country-section"
              style={{ flexGrow: "1", display: "flex", width: "50%" }}
            >
              <div style={{ width: "170px" }}>Confirm Password</div>
              <input
                type="password"
                name="confirmPassword"
                onChange={handleChange}
                autoComplete="current-password"
                value={formData.confirmPassword}
                style={{
                  marginLeft: "0px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginBottom: "0px",
                }}
                required
              />
            </div>
          </div>
          <br></br>

          {userid === "1" && (
            <>
              <div
                className="zip-country-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  className="admin-section"
                  style={{ flexGrow: "1", display: "flex", width: "50%" }}
                >
                  <div style={{ marginLeft: "30px", width: "180px" }}>
                    Admin
                  </div>
                  <input
                    type="checkbox"
                    name="isAdmin"
                    checked={formData.isAdmin}
                    onChange={handleChange}
                    style={{
                      marginLeft: "10px",
                      width: "auto",
                      height: "30px",
                      marginBottom: "0px",
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <br></br>

          <button
            type="submit"
            style={{
              cursor: "pointer",
              width: "200px",
              marginLeft: "50%",
              height: "38px",
              background: "#005668",
              borderRadius: "5px",
              color: "white",
              display: "flex",
              textalign: "center",
              fontSize: "medium",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default AddUsers;
