import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchLocations } from "../FetchData/FetchLocations";
import { fetchDepartments } from "../FetchData/FetchDepartments";
import LocationsDropdown from "../Dropdowns/LocationsDropdown";
import DepartmentsDropdown from "../Dropdowns/DepartmentsDropdown";

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    width: "60%",
    minHeight: "350px",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 9999,
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

Modal.setAppElement("#root");

const EditCategoryModal = ({
  isOpen,
  onRequestClose,
  CategoryData,
  onSave,
}) => {
  const initialFormData = {
    id: "",
    name: "",
    description: "",
    location_id: "",
    department_id: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [setFilteredDepartments] = useState([]);

  useEffect(() => {
    if (CategoryData) {
      // console.log(CategoryData.name);
      setFormData({
        id: CategoryData.id,
        name: CategoryData.name || "",
        description: CategoryData.description || "",
        location_id: CategoryData.location_id || "",
        department_id: CategoryData.department_id || "",
      });
      setSelectedLocationId(CategoryData.location_id);
      fetchAllData();
    }
    async function fetchAllData() {
      try {
        const locdata = await fetchLocations();
        const deptdata = await fetchDepartments();
        setLocations(locdata);
        setDepartments(deptdata);
        // console.log(locations);
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
  }, [CategoryData]);

  useEffect(() => {
    const filterDep = departments.filter(
      (department) => department.location_id === Number(selectedLocationId)
    );
    setFilteredDepartments(filterDep);
    // eslint-disable-next-line
  }, [departments, selectedLocationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "location_id") {
      // console.log(value, "loc");
      setSelectedLocationId(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSave(formData);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Location"
    >
      <>
        <div
          className="add-top-sec"
          style={{
            display: "inline-flex",
            alignItems: "baseline",
            marginTop: "0px",
          }}
        ></div>
        <h2>Edit Category</h2>

        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "0px",
          }}
        >
          <form
            className="myFormAdd"
            onSubmit={handleSubmit}
            style={{
              fontFamily: "inter",
              backgroundColor: "rgb(242 242 242)",
              width: "100%",
            }}
          >
            <h2
              style={{
                marginLeft: "30px",
              }}
              htmlFor=""
            >
              Category Location
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
                flexWrap: "wrap",
                flexDirection: "column",
                alignContent: "flex-start",
                marginRight: "30px",
              }}
            >
              <LocationsDropdown
                locations={locations}
                selectLocation={formData.location_id}
                setSelectLocation={(value) =>
                  setFormData({ ...formData, location_id: value })
                }
              />
              <br></br>
              {formData.location_id && (
                <DepartmentsDropdown
                  departments={departments}
                  selectDepartment={formData.department_id}
                  selectedLocation={formData.location_id}
                  setSelectDepartment={(value) =>
                    setFormData({ ...formData, department_id: value })
                  }
                />
              )}
              <br></br>
            </div>
            <h2
              style={{
                marginLeft: "30px",
              }}
              htmlFor=""
            >
              Cateogry Details
            </h2>
            <div
              className="store-name"
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginLeft: "30px",
                  width: "180px",
                }}
              >
                Cateogry Name{" "}
              </div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "30px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                maxLength={15}
                required
              />
            </div>
            <br></br>
            <div
              className="address"
              style={{
                display: "inline-flex",
                width: "100%",
                alignItems: "stretch",
              }}
            >
              <div style={{ marginLeft: "30px", width: "180px" }}>
                Description{" "}
              </div>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                style={{
                  marginLeft: "10px",
                  border: "none",
                  width: "50%",
                  height: "100px",
                  marginBottom: "20px",
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                cursor: "pointer",
                width: "200px",
                marginLeft: "30%",
                height: "38px",
                background: "#005668",
                borderRadius: "5px",
                color: "white",
                display: "flex",
                textalign: "center",
                fontSize: "medium",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Update
            </button>
          </form>
        </div>
      </>
    </Modal>
  );
};

export default EditCategoryModal;
